import { ChangeDetectionStrategy, Component, computed, input, Output, signal, Signal } from "@angular/core";
import { ModifiablePilotageElement, PilotageElement, PreviewPilotageElement } from "../services/scheduling.model";
import { comparing, Duration, HelsinkiDatePipe, insertSorted, Instant, InstantRange, minuteChanges, Predicate } from "common";
import { SchedulingService } from "../services/scheduling.service";
import { calculateCurrentRestInfo, calculateRestPeriods, JsRestMonitoringPeriod as RestMonitoringPeriod } from "pilotweb-kjs";
import { SchedulingPilot } from "apina-frontend";
import { CdkDragDrop, CdkDragEnter, CdkDragStart, DragDropModule } from "@angular/cdk/drag-drop";
import { SCHEDULING_PILOTAGE_HEIGHT, SchedulingPilotageComponent } from "../scheduling-pilotage/scheduling-pilotage.component";
import { layoutOnTimeline } from "../../common/timeline-layout";
import { TimelineComponent } from "../../timeline/timeline.component";
import { LanePositionDirective } from "../../timeline/lane-position.directive";
import { DurationPipe } from "../../common/pipes/duration.pipe";
import { TimeRangePipe } from "../../common/pipes/time-range.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { toObservable } from "@angular/core/rxjs-interop";
import { NgClass } from "@angular/common";

const ONGOING_REST_DURATION_CUTOFF = Duration.ofHours(4);

@Component({
    selector: 'app-schedule-lane',
    templateUrl: './schedule-lane.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        DragDropModule,
        DurationPipe,
        HelsinkiDatePipe,
        LanePositionDirective,
        MatIconModule,
        MatTooltipModule,
        SchedulingPilotageComponent,
        TimeRangePipe,
        TimelineComponent,
    ],
})
export class ScheduleLaneComponent {

    pilot = input<SchedulingPilot | null>(null);
    timeRange = input<InstantRange | undefined>();
    showTimeLane = input(false);

    private readonly draggedPilotage = signal<PilotageElement | null>(null);
    private readonly hiddenPilotage = signal<PilotageElement | null>(null);

    private readonly timerSignal = minuteChanges();

    readonly preferredRestFulfilled = computed(() => {
        const pilot = this.pilot();
        return pilot != null ? calculateCurrentRestInfo(pilot.preferredRestDuration, pilot.currentExplicitRestEnd, !pilot.location.resting, pilot.workPeriods, this.timerSignal()).preferredRestFulfilledAt : null;
    });

    readonly restPeriods: Signal<AugmentedRestMonitoringPeriod[]>;

    @Output()
    readonly pilotageDragged = toObservable(this.draggedPilotage);

    readonly laidOutPilotages: Signal<PilotageElementWithRow[]>;
    readonly laneHeight: Signal<number>;

    readonly boxHeight = SCHEDULING_PILOTAGE_HEIGHT;

    constructor(
        private readonly schedulingService: SchedulingService,
    ) {

        const allPilotagesSignal = computed(() => this.schedulingService.pilotagesForPilot(this.pilot()?.id ?? null));

        const pilotagesSignal = computed(() => {
            const dragged = this.draggedPilotage();
            const hidden = this.hiddenPilotage();
            let pilotages = allPilotagesSignal();

            if (dragged != null && !pilotages.includes(dragged))
                pilotages = insertSorted(pilotages, dragged, comparing(p => p.pilotage.startTime.toEpochMilli()));

            if (hidden != null)
                pilotages = pilotages.filter(p => p !== hidden);

            return pilotages;
        });

        this.laidOutPilotages = computed(() => layoutPilotages(allPilotagesSignal(), this.draggedPilotage(), this.hiddenPilotage()));

        this.laneHeight = computed(() => {
            const rowCount = 1 + Math.max(0, ...this.laidOutPilotages().map(p => p.row));

            return rowCount * SCHEDULING_PILOTAGE_HEIGHT;
        });

        this.restPeriods = computed(() => {
            const pilot = this.pilot();
            if (pilot == null)
                return [];

            const now = this.timerSignal();

            const workPeriods = [...pilot.workPeriods, ...upcomingWork(pilotagesSignal(), now)];
            return calculateRestPeriods(pilot.preferredRestDuration, pilot.location.resting, pilot.currentExplicitRestEnd, workPeriods, now).map(period => {
                // Don't show short ongoing rest period, possibly not enough space to render it
                const showOngoingStandbyRest = period.ongoingStandbyRest != null && period.ongoingStandbyRest.actualDuration().compareTo(ONGOING_REST_DURATION_CUTOFF) >= 0;

                // Don't use { ...period, showOngoingStandbyRest... } spread operator because
                // RestMonitoring period is actually class with getters that don't work with it.
                return {
                    start: period.start,
                    end: period.end,
                    nightWish: period.nightWish,
                    range: period.range,
                    mandatoryRestRange: period.mandatoryRestRange,
                    restViolation: period.restViolation,
                    restInHistory: period.restInHistory,
                    ongoingStandbyRest: period.ongoingStandbyRest,
                    overlappingWork: period.overlappingWork,
                    restShorterThanPilotPreferred: period.restShorterThanPilotPreferred,
                    locked: period.locked,
                    nightRest: period.nightWish,
                    mandatoryRestStart: period.mandatoryRestStart,
                    restDuration: period.restDuration,
                    showOngoingStandbyRest: showOngoingStandbyRest
                };
            });
        });
    }

    mayModifyAssignmentOfPilot(): boolean {
        return this.pilot()?.mayModifyAssignmentOfPilot ?? true;
    }

    mayDrop(): boolean {
        return this.pilot()?.mayModifyAssignmentOfPilot ?? true;
    }

    dropPilotage(event: CdkDragDrop<PilotageDrag>): void {
        const drag: PilotageDrag = event.item.data;
        this.schedulingService.pauseUpdates = false;

        this.schedulingService.assignPilotage(drag.pilotage, this.pilot()?.id ?? null);
        this.setDraggedPilotage(null);
    }

    dragStart(drag: CdkDragStart<PilotageDrag>, p: PilotageElement): void {
        this.schedulingService.pilotages.touch(p.id);

        const pilotageDrag = new PilotageDrag(p as ModifiablePilotageElement);
        pilotageDrag.showOnLane(this);

        drag.source.data = pilotageDrag;
        this.schedulingService.pauseUpdates = true;
        this.hiddenPilotage.set(p);
    }

    dragEnd(): void {
        this.schedulingService.pauseUpdates = false;
        this.setDraggedPilotage(null);
        this.hiddenPilotage.set(null);
    }

    dragEnter(event: CdkDragEnter<PilotageDrag>): void {
        event.item.data.showOnLane(this);
    }

    setDraggedPilotage(p: PilotageElement | null): void {
        this.draggedPilotage.set(p);
    }

    periodClasses(period: AugmentedRestMonitoringPeriod): string[] {
        const rows: string[] = [];

        if (period.restViolation)
            rows.push("bg-orange");
        else if (period.locked && !period.restInHistory)
            rows.push("bg-black");
        else
            rows.push("bg-gray-500");

        if (period.restViolation || period.restShorterThanPilotPreferred)
            rows.push("border-2", "border-orange");

        return rows;
    }
}

class PilotageDrag {

    private previousLane: ScheduleLaneComponent | null = null;

    constructor(readonly pilotage: ModifiablePilotageElement) {
    }

    showOnLane(lane: ScheduleLaneComponent): void {
        // drag-exit events don't work reliably, so keep track of the previous lane manually and
        // remove the drag from it whenever we enter a new lane.
        if (this.previousLane != null)
            this.previousLane.setDraggedPilotage(null);
        lane.setDraggedPilotage(new PreviewPilotageElement(this.pilotage, lane.pilot()));
        this.previousLane = lane;
    }
}

export interface PilotageElementWithRow {
    pilotage: PilotageElement;
    row: number;
}

interface AugmentedRestMonitoringPeriod extends RestMonitoringPeriod {
    ongoingStandbyRest: InstantRange | null | undefined; // redefine the property so that IDEA's analysis in template works
    showOngoingStandbyRest: boolean;
}

function layoutPilotages(pilotages: PilotageElement[], dragged: PilotageElement | null, hidden: PilotageElement | null): PilotageElementWithRow[] {
    let hiddenPredicate: Predicate<PilotageElement> | null = null;
    if (dragged != null && hidden != null && dragged.matches(hidden))
        hiddenPredicate = p => p === hidden;

    return layoutOnTimeline(pilotages, (a, b) => a.effectiveTimeRange().overlaps(b.effectiveTimeRange()), 0, dragged, hiddenPredicate)
        .map(it => ({pilotage: it.element, row: it.row}));
}

/** Clips range start by current time. */
function upcomingWork(pilotages: PilotageElement[], now: Instant): InstantRange[] {
    const result: InstantRange[] = [];
    for (const pilotage of pilotages) {
        const range = pilotage.totalTimeRange();
        if (!range.start.isBefore(now))
            result.push(range);
        else if (range.end.isAfter(now))
            result.push(new InstantRange(now, range.end));
    }

    return result;
}


