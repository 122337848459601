/* tslint:disable:no-input-rename */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { ComponentPortal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, ElementRef, Input, OnDestroy, TemplateRef, ViewContainerRef, } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const passiveListenerOptions = normalizePassiveListenerOptions({passive: true});

@Directive({
    selector: '[appSchedulingPilotageTooltip]',
    standalone: true,
})
export class SchedulingPilotageTooltipDirective implements OnDestroy, AfterViewInit {

    private _templateRef: TemplateRef<unknown> | null = null;
    private _overlayRef: OverlayRef | null = null;
    private _tooltipInstance: SchedulingPilotageTooltipComponent | null = null;
    private _portal!: ComponentPortal<SchedulingPilotageTooltipComponent>;
    private _disabled = false;

    @Input('appSchedulingPilotageTooltip')
    set tooltip(value: TemplateRef<unknown>) {
        this._templateRef = value;
    }

    @Input('appSchedulingPilotageTooltipDisabled')
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
        if (this._disabled)
            this.hide();
    }

    private readonly listeners = new Map<string, EventListenerOrEventListenerObject>();
    private readonly destroyed = new Subject<void>();

    constructor(
        private readonly _overlay: Overlay,
        private readonly _elementRef: ElementRef<HTMLElement>,
        private readonly _viewContainerRef: ViewContainerRef) {
    }

    ngAfterViewInit(): void {
        this.listeners
            .set('mouseenter', () => this.show())
            .set('mouseleave', () => this.hide());

        this.listeners.forEach((listener, event) => {
            this._elementRef.nativeElement.addEventListener(event, listener, passiveListenerOptions);
        });
    }

    ngOnDestroy(): void {
        const nativeElement = this._elementRef.nativeElement;

        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._tooltipInstance = null;
        }

        this.listeners.forEach((listener, event) => {
            nativeElement.removeEventListener(event, listener, passiveListenerOptions);
        });
        this.listeners.clear();

        this.destroyed.next();
        this.destroyed.complete();
    }

    private show(): void {
        if (this.disabled || !this._templateRef || (this._tooltipInstance?.visible ?? false))
            return;

        const overlayRef = this._createOverlay();
        this._detach();
        this._portal = this._portal || new ComponentPortal(SchedulingPilotageTooltipComponent, this._viewContainerRef);
        this._tooltipInstance = overlayRef.attach(this._portal).instance;
        this._tooltipInstance.portal = new TemplatePortal(this._templateRef, this._viewContainerRef);
        this._tooltipInstance.onHide
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => this._detach());
        this._tooltipInstance!.show();
    }

    private hide(): void {
        if (this._tooltipInstance)
            this._tooltipInstance.hide();
    }

    private _createOverlay(): OverlayRef {
        if (this._overlayRef)
            return this._overlayRef;

        const positionStrategy = this._overlay.position()
            .flexibleConnectedTo(this._elementRef)
            .withTransformOriginOn('.pilotage-tooltip-popup')
            .withFlexibleDimensions(false)
            .withViewportMargin(8)
            .withPositions(
                [
                    {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center'},
                    {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center'}
                ]
            );

        this._overlayRef = this._overlay.create({
            positionStrategy: positionStrategy,
            panelClass: 'pilotage-tooltip-panel'
        });

        this._overlayRef.detachments()
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => this._detach());

        return this._overlayRef;
    }

    private _detach(): void {
        if (this._overlayRef && this._overlayRef.hasAttached())
            this._overlayRef.detach();

        this._tooltipInstance = null;
    }
}

@Component({
    template: `
        <div class="pilotage-tooltip-popup">
            <ng-template [cdkPortalOutlet]="portal"></ng-template>
        </div>
    `,
    styles: [
            `
                .pilotage-tooltip-popup {
                    border-radius: 8px;
                    color: white;
                    background: black;
                    padding: 8px;
                }
            `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PortalModule]
})
class SchedulingPilotageTooltipComponent implements OnDestroy {

    visible = false;
    portal: TemplatePortal | null = null;

    readonly onHide = new Subject<void>();

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    }

    show(): void {
        this.visible = true;
        this.changeDetectorRef.markForCheck();
    }

    hide(): void {
        this.visible = false;
        this.onHide.next();
        this.changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        this.onHide.complete();
    }
}
