@if (!pilotage.preparationTime().isZero() && !pilotage.isAlarmAcknowledged()) {
    <div class="preparation mat-elevation-z6 text-[6pt] z-10 h-[15px] left-0 absolute px-[2px]"
         [class.wiggle]="pilotage.targeted()"
         [ngClass]="pilotage.isAlarmOverdue() ? 'text-white bg-orange' : 'text-black bg-white'"
         matTooltip="{{pilotage.preparationStartTime() | time}}"
         [style.top.px]="top()"
         [appLanePosition]="pilotage.preparationPeriod()">{{ pilotage.preparationStartTime() | time }}
    </div>
}

<div class="mat-elevation-z6 z-10 absolute top-0 bottom-0 right-0 text-[8pt] py-0 px-1 overflow-hidden whitespace-nowrap overflow-ellipsis box-border"
     [style.height.px]="pilotageHeight"
     [ngClass]="pilotageElementClasses()"
     [style.top.px]="top()"
     [appLanePosition]="pilotage.pilotageRef.pilotageTimeRange"
     (click)="touch(pilotage); openMenu($event, actionMenuTrigger)"
     placement="right">
    @if (!pilotage.isPilotConfirmed()) {
        <div class="absolute top-0 left-0 w-full h-full unconfirmed-background"></div>
    }
    <div class="absolute top-0 left-0 right-0 bottom-0 text-[5pt] py-[1px] px-[2px]"
         [ngClass]="!pilotage.pilotage.modifyAssignmentEnabled ? 'text-white bg-gray-500' : null"
         [appSchedulingPilotageTooltip]="tooltipTemplate"
         [appSchedulingPilotageTooltipDisabled]="pilotage.isPreview">
        @switch (pilotage.pilotage.tosComplianceStatus) {
            @case (TosComplianceStatus.SEVERE_TOS_VIOLATION) {
                <span class="absolute top-[1px] right-[1px] w-[6px] h-[6px] border border-black rounded-full bg-traffic-light-red"></span>
            }
            @case (TosComplianceStatus.TOS_VIOLATION) {
                <span class="absolute top-[1px] right-[1px] w-[6px] h-[6px] border border-black rounded-full bg-traffic-light-yellow"></span>
            }
        }
        @if (pilotage.pilotType === PilotType.SECOND_PILOT) {
            <span class="absolute bottom-0.5 left-0.5 text-inherit">2</span>
        }
        <span class="text-[6pt]">{{ pilotage.pilotage.vesselName }}</span><br>
        <span>{{ pilotage.pilotage.route.start.code }} - {{ pilotage.pilotage.route.end.code }}</span><br>
        <span>{{ pilotage.pilotageRef.pilotageTimeRange | timeRange }}</span>
    </div>
    <span #actionMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="actionMenu"></span>
</div>

<mat-menu #actionMenu="matMenu">
    <ng-template matMenuContent let-actions="actions">
        <app-menu [actions]="actions"></app-menu>
    </ng-template>
</mat-menu>

<ng-template #tooltipTemplate>
    <div class="flex flex-col gap-3 pilotage-tooltip">
        <div class="flex flex-col">
            <div class="font-bold">{{ pilotage.pilotage.vesselName }}</div>
            <div>{{ pilotage.pilotage.route.start.code }} - {{ pilotage.pilotage.route.end.code }}</div>
            <div>{{ pilotage.pilotageRef.pilotageTimeRange | timeRange }}</div>
        </div>

        @for (pi of pilotInfos(); track $index) {
            <div class="flex flex-row items-center">
                <mat-icon class="text-base">{{ pi.confirmed ? 'lock' : 'lock_open' }}</mat-icon>
                {{ pi.pilot.lastName }} {{ pi.pilot.firstName }} ({{ pi.pilot.initials }})
                {{ pi.alarmTime | time }}
            </div>
        }

        @switch (pilotage.pilotage.tosComplianceStatus) {
            @case (TosComplianceStatus.SEVERE_TOS_VIOLATION) {
                <div>Räikeästi palveluehtojen vastainen</div>
            }
            @case (TosComplianceStatus.TOS_VIOLATION) {
                <div>Palveluehtojen vastainen</div>
            }
        }
    </div>
</ng-template>
