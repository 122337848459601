import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ChronoUnit, Duration, durationAsHoursAndMinutes, ErrorService, HelsinkiDatePipe, Instant, InstantRange, MyMatDateTimePickerComponent, SpinnerButtonComponent } from "common";
import { PilotId, SchedulingEndpoint, SchedulingPilot } from "apina-frontend";
import { calculateCurrentRestInfo } from "pilotweb-kjs";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

@Component({
    templateUrl: './change-current-rest.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HelsinkiDatePipe,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MyMatDateTimePickerComponent,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        VerticalFormComponent,
    ],
})
export class ChangeCurrentRestComponent {

    private readonly pilotId: PilotId;
    readonly startTime: Instant;
    readonly endTime = new FormControl<Instant | null>(null, Validators.required);
    readonly minTime: Instant;
    readonly maxTime: Instant;
    readonly saving = signal(false);

    get duration(): string {
        const endTime = this.endTime.value;
        if (endTime != null) {
            const duration = new InstantRange(this.startTime, endTime).actualDuration();
            return durationAsHoursAndMinutes(duration);
        } else {
            return "-";
        }
    }

    constructor(private readonly dialogRef: MatDialogRef<ChangeCurrentRestComponent>,
                private readonly schedulingEndpoint: SchedulingEndpoint,
                private readonly errorService: ErrorService,
                @Inject(MAT_DIALOG_DATA) params: ChangeCurrentRestComponentParams) {

        const now = Instant.now().truncatedTo(ChronoUnit.MINUTES);
        const pilot = params.pilot;
        this.pilotId = pilot.id;

        const restInfo = calculateCurrentRestInfo(pilot.preferredRestDuration, pilot.currentExplicitRestEnd, !pilot.location.resting, pilot.workPeriods, now);

        this.startTime = (restInfo.restStartedAt || now).truncatedTo(ChronoUnit.MINUTES);

        this.minTime = this.startTime.plus(restInfo.mandatoryRestDuration);
        this.maxTime = this.startTime.plus(Duration.ofHours(24));
        this.endTime.reset(pilot.currentExplicitRestEnd ?? null);
    }

    save(): void {
        this.saving.set(true);
        this.schedulingEndpoint.setRestEnd(this.pilotId, this.endTime.value!).subscribe({
            next: () => this.dialogRef.close(true),
            error: e => {
                this.saving.set(false);
                return this.errorService.showUpdateError(e);
            }
        });
    }
}

export interface ChangeCurrentRestComponentParams {
    pilot: SchedulingPilot;
}

