<app-timeline
    class="relative"
    [timeRange]="timeRange()"
    [showTimeLane]="showTimeLane()"
    [minHeight]="boxHeight">

    <div class="relative schedule-lane"
         [ngClass]="pilot() != null ? 'assigned' : 'unassigned'"
         [style.height.px]="laneHeight()"
         cdkDropList
         [cdkDropListSortingDisabled]="true"
         (cdkDropListDropped)="dropPilotage($event)"
         (cdkDropListEntered)="dragEnter($event)">

        @for (period of pilot()?.notInDutyPeriods; track $index) {
            <div class="absolute h-full bg-[#d3d3d3]" [appLanePosition]="period" matTooltip="Ei työvuorossa"></div>
        }
        @for (secondment of pilot()?.secondments; track $index) {
            <div class="absolute h-2 bottom-0 bg-[#9ce79c]" [appLanePosition]="secondment.timeRange" matTooltip="Komennusmatkalla, {{secondment.stationName}}"></div>
        }

        @for (pr of laidOutPilotages(); track pr.pilotage.id) {
            <app-scheduling-pilotage cdkDrag
                                     [cdkDragDisabled]="!pr.pilotage.isDraggable() || !mayModifyAssignmentOfPilot()"
                                     (cdkDragStarted)="dragStart($event, pr.pilotage)"
                                     (cdkDragEnded)="dragEnd()"
                                     [pilotageWithRow]="pr">
                <ng-template cdkDragPlaceholder></ng-template>
                <ng-template cdkDragPreview></ng-template>
            </app-scheduling-pilotage>
        }

        @for (period of restPeriods(); track $index) {
            <div>
                @if (period.ongoingStandbyRest; as ongoing) {
                    <div class="flex flex-row items-center absolute z-[1] text-white bottom-0 h-[20px] text-[6pt] px-1 gap-1 overflow-hidden bg-gray-500 "
                         [appLanePosition]="ongoing"
                         matTooltip="Meneillään olevaa lepoa: {{ongoing | timeRange}} ({{ongoing.actualDuration() | duration: true}})">
                        @if (period.showOngoingStandbyRest) {
                            <span class="duration">{{ ongoing.actualDuration() | duration: true }}</span>
                        }
                    </div>
                }

                @if (period.mandatoryRestRange) {
                    <div class="flex flex-row items-center absolute z-[1] text-white bottom-0 h-[20px] text-[6pt] px-1 gap-1 overflow-hidden"
                         [ngClass]="periodClasses(period)"
                         [appLanePosition]="period.mandatoryRestRange"
                         matTooltip="Pakollinen lepo {{period.mandatoryRestRange | timeRange}}">
                        <div>{{ period.mandatoryRestStart | helsinkiDate: 'HH:mm' }}</div>
                        @if (period.nightWish) {
                            <mat-icon class="text-white small-icon">brightness_3</mat-icon>
                        }
                        <div class="flex-1"></div>
                        <div>{{ period.restDuration | duration: true }}</div>
                    </div>
                }
            </div>
        }
    </div>

    <div class="relative overflow-hidden h-[3px] w-full">
        @for (period of restPeriods(); track $index) {
            <div>
                <div class="absolute bottom-0 h-full bg-gray-300" [appLanePosition]="period.range" matTooltip="Seurantajakso {{period.range | timeRange}}" matTooltipClass="multiline"></div>
            </div>
        }
        @if (preferredRestFulfilled(); as preferredRestFulfilled) {
            <div class="absolute bottom-0 h-[10px] w-[4px] bg-[red]" [appLanePosition]="preferredRestFulfilled"></div>
        }
    </div>
</app-timeline>
