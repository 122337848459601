<div class="flex flex-row gap-2 items-start flex-wrap">
    <div class="flex flex-col gap-1">
        <mat-form-field>
            <mat-label>Luotsaukset</mat-label>
            <app-zone-picker stations="traffic-log" placeholder="Ei valintaa" noSelectionTitle="Ei valintaa" [formControl]="form.controls.pilotageZone"/>
        </mat-form-field>

        <mat-checkbox [formControl]="form.controls.includeWaitingPilotages">Arvio-tilaiset</mat-checkbox>
    </div>
    <div class="flex flex-col gap-1">
        <mat-form-field>
            <mat-label>Luotsit</mat-label>
            <app-zone-picker stations="traffic-log" placeholder="Oletus" noSelectionTitle="Oletus" [formControl]="form.controls.pilotZone"/>
        </mat-form-field>
        <mat-checkbox [formControl]="form.controls.includeAllPilots">Kaikki luotsit</mat-checkbox>
    </div>

    <mat-form-field class="w-[300px]">
        <mat-label>Alku</mat-label>
        <input matInput [matDatepicker]="startDatepicker" [formControl]="form.controls.startTime" placeholder="Kuluva ajanhetki">
        <button mat-icon-button [class.invisible]="form.controls.startTime.value == null" matIconSuffix (click)="form.controls.startTime.reset()">
            <mat-icon>clear</mat-icon>
        </button>
        <mat-datepicker-toggle matIconSuffix [for]="startDatepicker"/>
        <mat-datepicker #startDatepicker/>
    </mat-form-field>

    <mat-button-toggle-group [formControl]="form.controls.periodLength" class="mt-1">
        <mat-button-toggle [value]="1">1 vrk</mat-button-toggle>
        <mat-button-toggle [value]="2">2 vrk</mat-button-toggle>
        <mat-button-toggle [value]="3">3 vrk</mat-button-toggle>
    </mat-button-toggle-group>
</div>
