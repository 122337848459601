import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { ModifiablePilotageElement, PilotageElement } from "../services/scheduling.model";
import { SchedulingService } from "../services/scheduling.service";
import { PilotType, SchedulingPilot, SchedulingPilotagePilot, TosComplianceStatus } from "apina-frontend";
import { LegacyDialogsService } from "../../legacy/legacy-dialogs.service";
import { Instant, PilotageState, pilotageStateClasses, TimePipe } from "common";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { PilotageElementWithRow } from "../schedule-lane/schedule-lane.component";
import { PilotageService } from "../../pilotage/pilotage.service";
import { AppMenuComponent, MenuAction } from "../../common/menu/app-menu.component";
import { SchedulingPilotageTooltipDirective } from "./scheduling-pilotage-tooltip.directive";
import { LanePositionDirective } from "../../timeline/lane-position.directive";
import { TimeRangePipe } from "../../common/pipes/time-range.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { NgClass } from "@angular/common";

export const SCHEDULING_PILOTAGE_HEIGHT = 40;

@Component({
    selector: 'app-scheduling-pilotage',
    templateUrl: './scheduling-pilotage.component.html',
    styleUrls: ['./scheduling-pilotage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AppMenuComponent,
        LanePositionDirective,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        NgClass,
        SchedulingPilotageTooltipDirective,
        TimePipe,
        TimeRangePipe,
    ],
    host: {
        'class': 'z-10 whitespace-nowrap overflow-ellipsis'
    },
})
/**
 * Display and manage a single pilotage.
 */
export class SchedulingPilotageComponent {

    pilotageWithRow = input.required<PilotageElementWithRow>();

    readonly top = computed(() => this.pilotageWithRow().row * SCHEDULING_PILOTAGE_HEIGHT);

    readonly pilotageElementClasses = computed(() => {
        const pilotage = this.pilotageWithRow().pilotage;

        const classes: string[] = [pilotageStateClasses[pilotage.pilotage.state]];

        if (pilotage.targeted())
            classes.push("wiggle");

        if (pilotage.isPreview)
            classes.push("opacity-70");
        else if (pilotage.hasUnsavedChanges())
            classes.push("opacity-30");

        if (pilotage.pilotId != null && !pilotage.isPilotConfirmed()) {
            classes.push("border-2");
            if (pilotage.pilotage.modifyAssignmentEnabled) {
                classes.push("border-dashed");
                classes.push(pilotage.pilotType === PilotType.FIRST_PILOT ? "border-white" : "border-black");
            } else {
                classes.push("border-solid");
            }
        }

        return classes;
    });

    readonly TosComplianceStatus = TosComplianceStatus;
    readonly PilotType = PilotType;
    readonly pilotageHeight = SCHEDULING_PILOTAGE_HEIGHT;

    constructor(
        private readonly schedulingService: SchedulingService,
        private readonly legacyDialogsService: LegacyDialogsService,
        private readonly pilotageService: PilotageService,
    ) {
    }

    get pilotage(): PilotageElement {
        return this.pilotageWithRow().pilotage;
    }

    /**
     * Opens editor for alarm clock. Service updates are paused while editor is open.
     */
    private openAlarmClockEditor(pilotageElement: ModifiablePilotageElement): void {
        this.schedulingService.pauseUpdates = true;

        this.pilotageService.editAlarmTime(pilotageElement.pilotage.id, pilotageElement.pilotId!, pilotageElement.pilotType, pilotageElement.pilotage.startTime, pilotageElement.pilotagePilot().preparationTime).subscribe(preparationTime => {
            this.schedulingService.pauseUpdates = false;
            if (preparationTime !== undefined)
                pilotageElement.updateAlarmTime(preparationTime);
        });
    }

    pilotInfos(): PilotInfo[] {
        const pilotage = this.pilotage.pilotage;
        const infos: PilotInfo[] = [];

        const addPilotIfDefined = (pilot: SchedulingPilotagePilot): void => {
            if (pilot.id != null) {
                infos.push({
                    pilot: this.schedulingService.findPilot(pilot.id),
                    confirmed: pilot.confirmed,
                    alarmTime: pilot.preparationTime ? pilotage.startTime.minus(pilot.preparationTime) : null
                });
            }
        };

        addPilotIfDefined(pilotage.firstPilot);
        addPilotIfDefined(pilotage.secondPilot);

        return infos;
    }

    openMenu(event: MouseEvent, trigger: MatMenuTrigger): void {
        event.preventDefault();

        const pilotageElement = this.pilotageWithRow().pilotage as ModifiablePilotageElement;
        const schedulingService = this.schedulingService;
        const pilotage = pilotageElement.pilotage;

        const actions: MenuAction[] = [];
        const self = this;

        if (!pilotageElement.isPilotConfirmed()) {
            if (pilotage.mayAssign) {
                const pilotData = (pilotageElement.pilotId != null) ? schedulingService.findPilot(pilotageElement.pilotId) : null;
                const titleSuffix = (pilotData != null) ? ` (${pilotData.initials})` : "";

                actions.push({
                    title: `Kiinnitä ${pilotageElement.pilotNumber}. luotsi${titleSuffix}`,
                    icon: 'lock',
                    disabled: pilotData == null,
                    execute(): void {
                        const pilot1Id = pilotage.firstPilot.id;
                        const pilot2Id = pilotage.secondPilot.id;

                        // If first pilot is attached, we will confirm pilot boarding time even when the second pilot
                        // is attached. However, if second pilot is attached first, we won't ask the time at that point.
                        if (pilotageElement.pilotage.state === PilotageState.ORDER && pilot1Id != null) {
                            self.pilotageService.openPilotBoardingTimeDialog({
                                pilotageId: pilotageElement.id,
                                state: pilotage.state,
                                startTime: pilotage.startTime,
                                orderTime: pilotage.orderTime,
                                pilotBoardingTime: pilotage.pilotBoardingTime,
                                pilot1Initials: pilot1Id ? schedulingService.findPilot(pilot1Id).initials : null,
                                pilot2Initials: pilot2Id ? schedulingService.findPilot(pilot2Id).initials : null,
                                source: pilotage.scheduleSource,
                                tosComplianceStatus: pilotage.tosComplianceStatus,
                                save: false
                            }).subscribe(params => {
                                if (params !== undefined)
                                    schedulingService.confirmPilot(pilotageElement, params);
                            });
                        } else {
                            schedulingService.confirmPilot(pilotageElement);
                        }
                    }
                });
            }
        } else {
            if (pilotage.mayAssign) {
                actions.push({
                    title: `Poista ${pilotageElement.pilotNumber}. luotsin kiinnitys`,
                    icon: 'lock_open',
                    execute(): void {
                        schedulingService.unconfirmPilot(pilotageElement);
                    }
                });
            }
        }

        if (pilotageElement.canEditAlarmClock() && pilotageElement.pilotagePilot().mayModify) {
            actions.push({
                title: 'Herätysaika',
                icon: 'alarm',
                execute(): void {
                    self.openAlarmClockEditor(pilotageElement);
                }
            });
        }

        if (pilotageElement.canEditAlarmClock() && pilotageElement.pilotagePilot().mayModify && !pilotageElement.pilotagePilot().alarmAcknowledged && pilotageElement.isPilotConfirmed()) {
            actions.push({
                title: 'Kuittaa herätys',
                icon: 'alarm_off',
                execute(): void {
                    self.schedulingService.acknowledgeAlarm(pilotageElement);
                }
            });
        }

        if (pilotageElement.pilotage.mayModifyPilotage) {
            actions.push({
                title: 'Muokkaa luotsaustilausta',
                icon: 'edit',
                async execute(): Promise<void> {
                    if (await self.pilotageService.openEtaDialog(pilotageElement.id))
                        schedulingService.refreshData();
                }
            });
        }

        if (pilotageElement.canAddSecondPilot() && pilotage.mayAssign) {
            actions.push({
                title: 'Lisää 2. luotsi',
                icon: 'looks_two',
                execute(): void {
                    schedulingService.addSecondaryPilotage(pilotageElement);
                }
            });
        }

        if (pilotageElement.pilotType === PilotType.SECOND_PILOT && pilotage.mayAssign) {
            actions.push({
                title: 'Poista 2. luotsi',
                icon: 'remove',
                execute(): void {
                    schedulingService.removeSecondaryPilotage(pilotageElement);
                }
            });
        }

        if (pilotage.mayEditNotice) {
            actions.push({
                title: 'Huomautus',
                icon: 'note',
                execute(): void {
                    self.pilotageService.openNoticeEditor(pilotage.id);
                }
            });
        }

        if (actions.length === 0)
            return;

        if (pilotageElement.hasUnsavedChanges())
            for (const action of actions)
                action.disabled = true;

        trigger.menuData = {actions};
        trigger.openMenu();
    }

    touch(pilotage: PilotageElement): void {
        this.schedulingService.pilotages.touch(pilotage.id);
    }
}

interface PilotInfo {
    pilot: SchedulingPilot;
    confirmed: boolean;
    alarmTime: Instant | null;
}
