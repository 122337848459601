import { Comparator, comparators, comparing, Duration, Instant, InstantRange } from "common";
import { Minutes, PilotageId, PilotId, PilotType, SchedulingPilot, SchedulingPilotage, SchedulingPilotagePilot } from "apina-frontend";
import { Signal, signal, WritableSignal } from "@angular/core";

export type Percentage = number;

export const PILOTAGE_COMPARATOR: Comparator<PilotageElement> =
    comparators(comparing<PilotageElement>(p => p.pilotage.startTime.toEpochMilli()), comparing<PilotageElement>(p => p.pilotage.vesselName));

/**
 * A mutable wrapper containing a reference to SchedulingPilotage which remains immutable,
 * but which is replaced when pilotage is modified.
 */
export class MutableSchedulingPilotage {

    readonly id: PilotageId;
    private readonly _pilotage: WritableSignal<SchedulingPilotage>;
    readonly pilotage: Signal<SchedulingPilotage>;

    // states below are cached and assume that pilotage does not change on their part
    readonly pilotageTimeRange: InstantRange;

    constructor(pilotage: SchedulingPilotage) {
        this.id = pilotage.id;
        this._pilotage = signal(pilotage);
        this.pilotage = this._pilotage.asReadonly();

        this.pilotageTimeRange = new InstantRange(pilotage.startTime, pilotage.endTime);
    }

    updatePilot(pilotType: PilotType, func: (p: SchedulingPilotagePilot) => Partial<SchedulingPilotagePilot>): void {
        switch (pilotType) {
            case PilotType.FIRST_PILOT:
                this._pilotage.update(p => ({
                    ...p,
                    firstPilot: {...p.firstPilot, ...func(p.firstPilot)}
                }));
                break;
            case PilotType.SECOND_PILOT:
                this._pilotage.update(p => ({
                    ...p,
                    secondPilot: {...p.secondPilot, ...func(p.secondPilot)}
                }));
                break;
        }
    }

    removeSecondPilot(): void {
        this._pilotage.update(p => ({
            ...p,
            secondPilot: {...p.secondPilot, id: null}
        }));
    }
}

/**
 * Represents a pilotage on the time lane. A single pilotage may have two concrete elements because
 * both pilots have their own elements (if there are two pilots). In addition to this, there can be
 * preview elements when dragging.
 *
 * @see ModifiablePilotageElement
 * @see PreviewPilotageElement
 */
export abstract class PilotageElement {

    abstract readonly isPreview: boolean;
    abstract readonly pilotId: PilotId | null;

    abstract targeted(): boolean;

    protected constructor(
        readonly pilotageRef: MutableSchedulingPilotage,
        readonly pilotType: PilotType,
    ) {
    }

    get id(): PilotageId {
        return this.pilotage.id;
    }

    abstract hasUnsavedChanges(): boolean;

    get pilotage(): Readonly<SchedulingPilotage> {
        return this.pilotageRef.pilotage();
    }

    abstract preparationTime(): Duration;

    isAlarmAcknowledged(): boolean {
        return !this.isPreview && this.pilotagePilot().alarmAcknowledged;
    }

    isAlarmOverdue(): boolean {
        const pilotage = this.pilotage;
        const pilot = this.pilotagePilot();
        const alarmTime = pilotage.startTime.minus(this.preparationTime());
        return !this.isPreview && !pilot.alarmAcknowledged && alarmTime != null && Instant.now().isAfter(alarmTime); // TODO should be observable based on clock
    }

    isPilotConfirmed(): boolean {
        return !this.isPreview && this.pilotagePilot().confirmed;
    }

    isDraggable(): boolean {
        const pilotage = this.pilotage;
        const pilot = this.pilotagePilot();
        return !this.isPreview && pilotage.modifyAssignmentEnabled && pilotage.mayAssign && !this.hasUnsavedChanges() && !pilot.confirmed;
    }

    preparationStartTime(): Instant {
        return this.pilotage.startTime.minus(this.preparationTime());
    }

    preparationPeriod(): InstantRange {
        return new InstantRange(this.preparationStartTime(), this.pilotage.startTime);
    }

    get pilotNumber(): number {
        switch (this.pilotType) {
            case PilotType.FIRST_PILOT:
                return 1;
            case PilotType.SECOND_PILOT:
                return 2;
        }
    }

    effectiveTimeRange(): InstantRange {
        return !this.isAlarmAcknowledged() ? this.totalTimeRange() : this.pilotageRef.pilotageTimeRange;
    }

    abstract touch(): void;

    matches(element: PilotageElement): boolean {
        return this.pilotage === element.pilotage && this.pilotType === element.pilotType;
    }

    canAddSecondPilot(): boolean {
        const pilotage = this.pilotage;
        return this.pilotType === PilotType.FIRST_PILOT && pilotage.firstPilot.id != null && pilotage.secondPilot.id == null;
    }

    canEditAlarmClock(): boolean {
        return this.pilotId != null;
    }

    pilotagePilot(): Readonly<SchedulingPilotagePilot> {
        switch (this.pilotType) {
            case PilotType.FIRST_PILOT:
                return this.pilotage.firstPilot;
            case PilotType.SECOND_PILOT:
                return this.pilotage.secondPilot;
        }
    }

    totalTimeRange(): InstantRange {
        return new InstantRange(this.preparationStartTime(), this.pilotage.endTime);
    }
}

/**
 * Represents pilotages of actual state.
 */
export class ModifiablePilotageElement extends PilotageElement {

    /** Is this pilotage targeted and should be presented especially */
    private readonly _targeted = signal(false);

    /** Does the displayed element contain changes that are not yet saved to server side? */
    private readonly dirty = signal(false);

    constructor(pilotage: MutableSchedulingPilotage, pilotType: PilotType, targeted: boolean = false) {
        super(pilotage, pilotType);
        this._targeted.set(targeted);
    }

    get isPreview(): boolean {
        return false;
    }

    hasUnsavedChanges(): boolean {
        return this.dirty();
    }

    markAsDirty(changes: boolean): void {
        this.dirty.set(changes);
    }

    preparationTime(): Duration {
        return this.pilotagePilot().preparationTime ?? Duration.ZERO;
    }

    targeted(): boolean {
        return this._targeted();
    }

    touch(): void {
        this._targeted.set(false);
    }

    get pilotId(): PilotId | null {
        return this.pilotagePilot().id;
    }

    confirmPilot(): void {
        this.updatePilot(() => ({confirmed: true}));
    }

    unconfirmPilot(): void {
        this.updatePilot(() => ({confirmed: false}));
    }

    updateAlarmTime(minutes: Minutes): void {
        this.updatePilot(() => ({preparationTime: Duration.ofMinutes(minutes)}));
    }

    assignPilot(pilotId: PilotId | null, mayModify: boolean, preparationTime: Duration | null): void {
        this.updatePilot(() => ({
            id: pilotId,
            confirmed: false,
            mayModify: mayModify,
            preparationTime,
        }));
    }

    acknowledgeAlarm(): void {
        this.updatePilot(() => ({
            alarmAcknowledged: true
        }));
    }

    private updatePilot(func: (p: SchedulingPilotagePilot) => Partial<SchedulingPilotagePilot>): void {
        this.pilotageRef.updatePilot(this.pilotType, func);
    }
}

/**
 * Temporary representation for elements that are created during dragging.
 */
export class PreviewPilotageElement extends PilotageElement {

    readonly pilotId: PilotId | null;

    constructor(element: PilotageElement, private readonly pilot: SchedulingPilot | null) {
        super(element.pilotageRef, element.pilotType);

        this.pilotId = pilot?.id ?? null;
    }

    hasUnsavedChanges(): boolean {
        return false;
    }

    targeted(): boolean {
        return false;
    }

    get isPreview(): boolean {
        return true;
    }

    touch(): void {
    }

    override preparationTime(): Duration {
        return this.pilot?.fullPreparationTime ?? Duration.ZERO;
    }
}
