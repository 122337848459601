import { Injectable } from "@angular/core";
import { firstValueFrom, interval } from "rxjs";
import { first, map } from "rxjs/operators";
import { buildUrl } from "common";
import { ChangeLogEntityType, PilotageId, PilotId, RouteChainId, RouteId, TrafficLogPilotage, VesselId } from "apina-frontend";
import { changelogEntityTypeToUrlFormat } from "../changelog/changelog.component";
import { createSchedulingUrl } from "../scheduling/scheduling.component";


@Injectable({providedIn: "root"})
export class LegacyDialogsService {

    openChangelog(entityType: ChangeLogEntityType, entityId: number): void {
        const path = `/changelog/${encodeURIComponent(changelogEntityTypeToUrlFormat(entityType))}/${encodeURIComponent(entityId)}`;
        this.openDialog(path, "menubar=false,scrollbars,height=700,width=900,top=0,resizable");
    }

    openPilotageInfoDialog(pilotageId: PilotageId): void {
        this.openDialog(`/pilotage/${pilotageId}/details`, "menubar=false,scrollbars,height=708,width=770,top=0,resizable");
    }

    openPilotLocationEvents(pilotId: PilotId): void {
        this.openDialog(
            `/pilot-timesheet/${pilotId}`,
            "menubar=false,scrollbars,height=600,width=920,top=0,resizable"
        );
    }

    openScheduling(pilotage: TrafficLogPilotage): void {
        this.openDialog(createSchedulingUrl(pilotage), "menubar=false,scrollbars,height=750,width=1280,top=0,resizable");
    }

    openPilotageComments(pilotageId: PilotageId): void {
        this.openDialog(`/comments/${encodeURIComponent(pilotageId)}`, "menubar=false,scrollbars,height=708,width=" + 570 + ",top=0,resizable");
    }

    openNewVesselDialog(): Promise<void> {
        return this.openDialog(`/vessels/new`, "menubar=false,scrollbars,height=800,width=670,top=0,resizable");
    }

    openEditVesselDialog(vesselId: VesselId): Promise<void> {
        return this.openDialog(`/vessels/${vesselId}/edit`, "menubar=false,scrollbars,height=800,width=670,top=0,resizable");
    }

    openVesselDialog(vesselId: VesselId): void {
        this.openDialog(`/vessels/${vesselId}`, "menubar=false,scrollbars,height=750,width=670,top=0,resizable");
    }


    openRouteSelectionWindow(start: string, callback: RouteSelectedCallback): void {
        window.routeSelected = callback;
        this.openDialog(buildUrl("/routes/select", {start}), "menubar=false,scrollbars,height=700,width=700,top=0,resizable");
    }

    openRouteChainSelectionWindow(start: string, callback: RouteChainSelectedCallback): void {
        window.routeChainSelected = callback;

        this.openDialog(buildUrl("/routes/select-chain", {start}), "menubar=false,scrollbars,height=700,width=700,top=0,resizable");
    }

    private openDialog(path: string, features: string): Promise<void> {
        return openWindow(path, features);
    }
}

type RouteSelectedCallback = (id: RouteId, startCode: string, endCode: string) => void;
type RouteChainSelectedCallback = (id: RouteChainId) => void;

declare global {
    interface Window {
        routeSelected?: RouteSelectedCallback;
        routeChainSelected?: RouteChainSelectedCallback;
    }
}

/**
 * Opens a given page in a new window and returns an observable that resolves when the window is closed.
 */
export function openWindow(path: string, features: string): Promise<void> {
    const win = window.open(path, "", features);

    return firstValueFrom(interval(200)
        .pipe(
            first(() => win != null && win.closed),
            map(() => undefined)));
}
