import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, signal } from "@angular/core";
import { SchedulingPilot, SchedulingPilotLocation } from "apina-frontend";
import { MatDialog } from "@angular/material/dialog";
import { ChangeCurrentRestComponent, ChangeCurrentRestComponentParams } from "../change-current-rest/change-current-rest.component";
import { of, timer } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Duration, formatMinutes, Instant, MILLIS_IN_MINUTE } from "common";
import { calculateCurrentRestInfo } from "pilotweb-kjs";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-pilot-location',
    templateUrl: './pilot-location.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    ],
})
export class PilotLocationComponent {

    private readonly _pilot = signal<SchedulingPilot | null>(null);

    readonly data: Signal<PilotLocationViewData | null> = toSignal(toObservable(this._pilot).pipe(switchMap(pilot => {
        if (pilot == null)
            return of(null);

        return timer(0, MILLIS_IN_MINUTE).pipe(map(() => ({
            details: buildDetails(pilot, Instant.now()),
            location: pilot.location,
            allowRestChange: pilot.restMonitorPeriodIsActive && pilot.mayModifyPilot
        })));
    })), {initialValue: null});

    @Output()
    readonly restChanged = new EventEmitter<void>();

    constructor(private readonly matDialog: MatDialog) {
    }

    @Input() set pilot(pilot: SchedulingPilot) {
        this._pilot.set(pilot);
    }

    setRest(event: MouseEvent): void {
        event.preventDefault();
        this.matDialog.open<ChangeCurrentRestComponent, ChangeCurrentRestComponentParams>(ChangeCurrentRestComponent, {
            data: {
                pilot: this._pilot()!
            }
        }).afterClosed().subscribe(result => {
            if (result)
                this.restChanged.emit();
        });
    }
}

function buildDetails(pilot: SchedulingPilot, now: Instant): string {
    if (pilot.currentExplicitRestEnd != null)
        return "L: " + formatMinutes(Duration.between(now, pilot.currentExplicitRestEnd).toMinutes());

    const result = [];

    const info = calculateCurrentRestInfo(pilot.preferredRestDuration, pilot.currentExplicitRestEnd, !pilot.location.resting, pilot.workPeriods, now);
    if (info.preferredRestFulfilledAt != null)
        result.push("L: " + formatMinutes(Duration.between(now, info.preferredRestFulfilledAt).toMinutes()));

    if (info.preferredRestStart != null)
        result.push("T: " + formatMinutes(Duration.between(now, info.preferredRestStart).toMinutes()));

    return result.join(" ");
}

interface PilotLocationViewData {
    details: string;
    location: SchedulingPilotLocation;
    allowRestChange: boolean;
}
