import { Injectable, signal, WritableSignal } from "@angular/core";
import { arrayWithout, ChronoUnit, Duration, ErrorService, insertSorted, Instant, InstantRange, MILLIS_IN_SECOND, PilotStationId } from "common";
import { ModifiablePilotageElement, MutableSchedulingPilotage, PILOTAGE_COMPARATOR, PilotageElement } from "./scheduling.model";
import { combineLatest, concat, Observable, of as observableOf, repeat, retry, Subject } from "rxjs";
import { PilotageId, PilotConfirmationStatus, PilotId, PilotType, SchedulingEndpoint, SchedulingLoadResult, SchedulingPilot, SchedulingPilotage, UpdatePilotBoardingTimeParams } from "apina-frontend";
import { catchError, concatMap, map, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { SchedulingQueryParams } from "../filters/scheduling-filters.component";
import { PilotageService } from "../../pilotage/pilotage.service";
import { AccountService } from "../../account/account.service";
import { equalLogin } from "../../domain/login";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

/** How often we refresh data from the server */
const REFRESH_PERIOD_MILLIS = 120 * MILLIS_IN_SECOND;

/**
 * How long to wait before retrying when refreshing fails. This covers both communication failures
 * and situations where we couldn't refresh data because user was editing data.
 */
const REFRESH_RETRY_MILLIS = 10 * MILLIS_IN_SECOND;

/**
 * If current time is selected as the start, we still want to show some context before current time.
 * This specifies how long period of time we show.
 */
const TIME_TO_SHOW_BEFORE_CURRENT_TIME = Duration.ofHours(6);

@Injectable()
/**
 * Service responsible for updating the local scheduling data-model.
 *
 * Individual components can ask for an object-graph and listen to the changes on the graph,
 * but all modifications to the model should happen through this service.
 *
 * The service is scoped to `SchedulingComponent` so if there are multiple instances of that,
 * they will each receive their own instance of this service.
 */
export class SchedulingService {

    readonly pilotages = new Pilotages();
    private readonly pilots = new Map<PilotId, SchedulingPilot>();
    private readonly searches = new Subject<SchedulingQueryParams>();
    private readonly refreshes = new Subject<SchedulingQueryParams | null>();
    private readonly _results = signal<SchedulingData | null>(null);
    readonly results = this._results.asReadonly();

    /**
     * How many modifications are active at the current moment?
     *
     * We may modify pilotages before changes to other pilotages have been saved. This means
     * that this count may be higher than 1. However, we must never update pilotage data when
     * there are ongoing modifications (count != 0).
     */
    private activeModifications = 0;

    /**
     * Is this user currently interacting with the UI in a way that we wish to prevent updates?
     *
     * Examples:
     *   - user is currently dragging
     *   - user has opened a modal edit-dialog
     */
    pauseUpdates = false;

    /**
     * Has our data been updates since we last executed a query?
     */
    private hasUpdatesSinceLastQuery = false;

    constructor(private readonly schedulingEndpoint: SchedulingEndpoint,
                pilotageService: PilotageService,
                accountService: AccountService,
                private readonly errorService: ErrorService) {

        const refreshedSearches$ = combineLatest([this.searches, this.refreshes.pipe(startWith(null))])
            .pipe(map(([a]) => a));

        const results$ = refreshedSearches$.pipe(
            switchMap(criteria => concat([null], this.executeSearch(criteria))),
            shareReplay(1));

        results$.pipe(takeUntilDestroyed()).subscribe(data => {
            if (data != null)
                this.updateModel(data);
        });

        combineLatest([pilotageService.pilotageUpdates$, accountService.currentUserInfo$]).pipe(takeUntilDestroyed()).subscribe(([update, user]) => {
            if (equalLogin(update.user, user.login)) {
                this.refreshData();
            }
        });
    }

    /**
     * Trigger a new search.
     */
    search(criteria: SchedulingQueryParams): void {
        if (criteria.pilotagePilotStationIds.length !== 0 && criteria.pilotPilotStationIds.length !== 0) {
            this._results.set(null);
            this.searches.next({...criteria});
        } else {
            this._results.set({pilots: [], timeRange: null});
        }
    }

    refreshData(): void {
        this.refreshes.next(null);
    }

    /**
     * Returns an observable that keeps on executing given search indefinitely.
     */
    private executeSearch(criteria: SchedulingQueryParams): Observable<SchedulingLoadResultWithTimeRange> {
        // We begin our stream with Observable.of(null).concatMap(() => foo()) instead of just foo()
        // to make sure that repeatWhen(...) is able to restart the whole thing after refresh period.
        // Why not just start with Observable.timer(...)? Because we don't want to grow the repeat
        // queue indefinitely if executing query (or showing error dialog) takes a longer time than
        // refresh period.

        return observableOf(null).pipe(
            concatMap(() => this.executeQuery(criteria)),
            tap(() => this.verifyUpdatePreconditions()), // trigger an error and retry if we can't update now
            retry({delay: REFRESH_RETRY_MILLIS}),
            repeat({delay: REFRESH_PERIOD_MILLIS}));
    }

    private executeQuery(criteria: SchedulingQueryParams): Observable<SchedulingLoadResultWithTimeRange> {
        this.hasUpdatesSinceLastQuery = false;

        // This check is strictly not necessary: our correctness depends only on the check
        // performed by updateModel. However, since we'd end up doing a request whose result
        // would be discarded anyway, it's a good optimization to fail early.
        this.verifyUpdatePreconditions();

        const timeRange = criteria.startTime != null
            ? new InstantRange(criteria.startTime, criteria.startTime.plus(criteria.duration))
            : this.rangeForCurrentTime(criteria.duration);
        return this.schedulingEndpoint.search({
            timeRange,
            pilotagePilotStationIds: criteria.pilotagePilotStationIds,
            pilotPilotStationIds: criteria.pilotPilotStationIds,
            includeWaitingPilotages: criteria.waitingPilotages,
            includeAllPilots: criteria.allPilots
        }).pipe(
            map(p => ({...p, timeRange})),
            catchError(error => this.errorService.showLoadError(error).pipe(map(() => {
                throw error;
            }))));
    }

    private rangeForCurrentTime(duration: Duration): InstantRange {
        const now = Instant.now().truncatedTo(ChronoUnit.MINUTES);
        return new InstantRange(now.minus(TIME_TO_SHOW_BEFORE_CURRENT_TIME), now.plus(duration));
    }

    private verifyUpdatePreconditions(): void {
        if (this.activeModifications !== 0 || this.pauseUpdates || this.hasUpdatesSinceLastQuery)
            throw `preconditions for updateModel were not met: activeModifications: ${this.activeModifications}, dragging: ${this.pauseUpdates}, hasUpdatesSinceLastQuery: ${this.hasUpdatesSinceLastQuery}`;
    }

    /**
     * Takes the loaded model and updates our internal state based on that.
     */
    private updateModel(data: SchedulingLoadResultWithTimeRange): void {
        for (const pilot of data.pilots)
            this.pilots.set(pilot.id, pilot);

        this._results.set({timeRange: data.timeRange, pilots: data.pilots});
        this.pilotages.reset(this.pilots, data.pilotages);
    }

    pilotagesForPilot(id: PilotId | null): PilotageElement[] {
        return this.pilotages.forPilot(id);
    }

    confirmPilot(pilotage: ModifiablePilotageElement, params?: UpdatePilotBoardingTimeParams): void {
        if (pilotage.isPilotConfirmed()) return;

        this.savePilotage(pilotage, {
            save: () => this.schedulingEndpoint.confirmPilot(pilotage.id, pilotage.pilotType, pilotage.pilotId!, params ?? {
                latePilotBoardingTimeReason: null,
                pilotBoardingTime: null,
                actionsTakenToDeliverPilot: null
            }),
            onSuccess: () => pilotage.confirmPilot()
        });
    }

    unconfirmPilot(pilotage: ModifiablePilotageElement): void {
        if (!pilotage.isPilotConfirmed()) return;

        this.savePilotage(pilotage, {
            save: () => this.schedulingEndpoint.unconfirmPilot(pilotage.id, pilotage.pilotType, pilotage.pilotId!),
            onSuccess: () => pilotage.unconfirmPilot()
        });
    }

    addSecondaryPilotage(pilotage: ModifiablePilotageElement): void {
        const firstPilot = pilotage.pilotage.firstPilot;

        this.savePilotage(pilotage, {
            save: () => this.schedulingEndpoint.assignPilotage(pilotage.id, {pilotType: PilotType.SECOND_PILOT, oldPilotId: null, newPilotId: firstPilot.id, status: PilotConfirmationStatus.PRESCHEDULED}),
            onSuccess: () => {
                const element = new ModifiablePilotageElement(pilotage.pilotageRef, PilotType.SECOND_PILOT);
                element.touch();
                element.assignPilot(firstPilot.id, firstPilot.mayModify, firstPilot.preparationTime);
                this.pilotages.add(element);
            }
        });
    }

    removeSecondaryPilotage(element: ModifiablePilotageElement): void {
        const pilotageRef = element.pilotageRef;
        const secondPilotId = pilotageRef.pilotage().secondPilot.id;

        this.savePilotage(element, {
            save: () => {
                // If there's no second pilot (the pilotage element was dragged to unattached-lane and then removed),
                // the server state is already correct and it suffices to update the local state.
                if (secondPilotId == null)
                    return observableOf(null);
                else
                    return this.schedulingEndpoint.assignPilotage(pilotageRef.id, {pilotType: PilotType.SECOND_PILOT, oldPilotId: secondPilotId, newPilotId: null, status: PilotConfirmationStatus.PRESCHEDULED});
            },
            onSuccess: () => {
                this.pilotages.remove(element);
                pilotageRef.removeSecondPilot();
            }
        });
    }

    assignPilotage(pilotage: ModifiablePilotageElement, newPilotId: PilotId | null): void {
        const oldPilotId = pilotage.pilotId;
        if (oldPilotId === newPilotId)
            return;

        const oldPilotPreparationTime = pilotage.preparationTime();
        const oldPilotMayModify = pilotage.pilotagePilot().mayModify;

        // Optimistically update the model so that our drag completes right away.
        // If the saving fails, then undo this in error handler.
        this.savePilotage(pilotage, {
            prepare: () => {
                const pilotData = newPilotId != null ? this.pilots.get(newPilotId) : null;
                this.pilotages.swapPilot(pilotage, newPilotId, pilotData?.mayModifyPilot === true, pilotData?.fullPreparationTime ?? null);
            },
            save: () => this.schedulingEndpoint.assignPilotage(pilotage.id, {pilotType: pilotage.pilotType, oldPilotId, newPilotId, status: PilotConfirmationStatus.PRESCHEDULED}),
            onFailure: () => this.pilotages.swapPilot(pilotage, oldPilotId, oldPilotMayModify, oldPilotPreparationTime)
        });
    }

    acknowledgeAlarm(pilotage: ModifiablePilotageElement): void {
        this.savePilotage(pilotage, {
            save: () => this.schedulingEndpoint.acknowledgeAlarm(pilotage.id, pilotage.pilotId!),
            onSuccess: () => pilotage.acknowledgeAlarm()
        });
    }

    /**
     * Executes a modification against given pilotage in the backend service.
     * Takes care of marking the model as being saved and provides common error handling.
     */
    private savePilotage<T>(pilotage: ModifiablePilotageElement, callbacks: ModifyPilotageCallbacks<T>): void {
        this.activeModifications++;
        this.hasUpdatesSinceLastQuery = true;

        pilotage.markAsDirty(true);

        if (callbacks.prepare)
            callbacks.prepare();

        callbacks.save().subscribe({
            next: value => {
                pilotage.markAsDirty(false);
                if (callbacks.onSuccess)
                    callbacks.onSuccess(value);
                this.activeModifications--;
            },
            error: error => {
                this.errorService.showUpdateError(error).subscribe(() => {
                    pilotage.markAsDirty(false);
                    if (callbacks.onFailure)
                        callbacks.onFailure(error);
                    this.activeModifications--;
                });
            }
        });
    }

    findPilot(pilotId: PilotId): SchedulingPilot {
        const pilot = this.pilots.get(pilotId);
        if (pilot != null)
            return pilot;
        else
            throw `pilot not found ${pilotId}`;
    }
}

interface ModifyPilotageCallbacks<T> {
    prepare?(): void;

    save(): Observable<T>;

    onSuccess?(value: T): void;

    onFailure?(error: unknown): void;
}

class Pilotages {

    private readonly assigned = new Map<PilotId, WritableSignal<PilotageElement[]>>();
    private readonly unassigned = signal<PilotageElement[]>([]);

    targetedPilotage: PilotageId | null = null;

    touch(pilotageId: PilotageId): void {
        for (const sub of this.assigned.values())
            this.touchMatching(sub, pilotageId);

        this.touchMatching(this.unassigned, pilotageId);
    }

    private touchMatching(subject: WritableSignal<PilotageElement[]>, pilotageId: PilotageId): void {
        const elements = subject().filter(it => it.pilotage.id === pilotageId);
        if (elements.length) {
            for (const p of elements)
                p.touch();
            subject.set(subject());
        }
    }

    swapPilot(pilotage: ModifiablePilotageElement, pilotId: PilotId | null, mayModify: boolean, preparationTime: Duration | null): void {
        const oldPilotages = this.signalForPilot(pilotage.pilotId);
        oldPilotages.update(ps => arrayWithout(ps, pilotage));

        pilotage.assignPilot(pilotId, mayModify, preparationTime);
        this.add(pilotage);
    }

    /**
     * Reset the model to contain data about given pilots and pilotages.
     *
     * Takes care to reuse existing subjects for all pilots that were previously assigned,
     * so that existing subscriptions don't break.
     *
     * @param pilots all available pilots
     * @param pilotages pilotages assigned to pilots. The ids of assigned pilots must be subset of pilotIds.
     */
    reset(pilots: Map<PilotId, SchedulingPilot>, pilotages: SchedulingPilotage[]): void {
        const pilotIds = Array.from(pilots.keys());

        // The code here is a bit involved because we wish to do two things to make things simpler
        // and more performant as far as the rest of the system is concerned:
        //
        //  1. we want to reuse existing subjects as possible (so that existing subscriptions work)
        //  2. we wish to perform only single update to the subject (so subscribers don't need to do unnecessary work)
        //
        // To meet these goals we'll first build local mappings containing the new pilotage-arrays. When those
        // are finally constructed, we'll update each subject to its value.

        // First create a mapping from pilot-ids to pilotages (null for unassigned)
        const pilotagesByPilotId = new Map<PilotId | null, PilotageElement[]>();
        pilotagesByPilotId.set(null, []);
        for (const pilotId of pilotIds)
            pilotagesByPilotId.set(pilotId, []);

        // Provide some helpers
        const addPilotageElement = (pilotage: MutableSchedulingPilotage, pilotId: PilotId | null, pilotType: PilotType): void => {
            const pilotPilotages = pilotagesByPilotId.get(pilotId);

            const targeted = pilotage.id === this.targetedPilotage;
            const element = new ModifiablePilotageElement(pilotage, pilotType, targeted);
            // If the server ends up sending pilotages with unknown pilots, ignore them
            if (pilotPilotages != null)
                pilotPilotages.push(element);
        };

        const lookupPilotages = (pilotId: PilotId | null): PilotageElement[] =>
            pilotagesByPilotId.get(pilotId)!.sort(PILOTAGE_COMPARATOR);

        // Next populate the mappings
        for (const pilotage of pilotages) {
            const p = new MutableSchedulingPilotage(pilotage);

            addPilotageElement(p, pilotage.firstPilot.id, PilotType.FIRST_PILOT);

            if (pilotage.secondPilot.id != null)
                addPilotageElement(p, pilotage.secondPilot.id, PilotType.SECOND_PILOT);
        }

        // Finally update the subjects...

        // ... unassigned is easy
        this.unassigned.set(lookupPilotages(null));

        // ... for each pilot, update existing or create a new subject
        for (const pilotId of pilotIds) {
            const pilotPilotages = lookupPilotages(pilotId);
            const subject = this.assigned.get(pilotId);
            if (subject != null)
                subject.set(pilotPilotages);
            else
                this.assigned.set(pilotId, signal(pilotPilotages));
        }

        // ... finally remove the obsolete subjects
        for (const pilotId of Array.from(this.assigned.keys()))
            if (pilotIds.indexOf(pilotId) === -1)
                this.assigned.delete(pilotId);
    }

    forPilot(pilotId: PilotId | null): PilotageElement[] {
        const pilotages = this.signalForPilot(pilotId);

        if (pilotages == null)
            throw `Pilot ${pilotId} has no pilotages`;

        return pilotages();
    }

    private signalForPilot(pilotId: PilotId | null): WritableSignal<PilotageElement[]> {
        return (pilotId != null) ? this.assigned.get(pilotId)! : this.unassigned;
    }

    add(pilotage: PilotageElement): void {
        const pilotages = this.signalForPilot(pilotage.pilotId);

        pilotages.update(ps => insertSorted(ps, pilotage, PILOTAGE_COMPARATOR));
    }

    remove(pilotage: PilotageElement): void {
        const pilotages = this.signalForPilot(pilotage.pilotId);

        pilotages.update(ps => arrayWithout(ps, pilotage));
    }
}

export interface SearchCriteria {
    pilotagePilotStationIds: PilotStationId[];
    pilotPilotStationIds: PilotStationId[];
    start: Instant | null;
    duration: Duration;
    includeWaitingPilotages: boolean;
    includeAllPilots: boolean;
}

export interface SchedulingData {
    readonly timeRange: InstantRange | null;
    readonly pilots: ReadonlyArray<AugmentedSchedulingPilot>;
}

export interface AugmentedSchedulingPilot extends SchedulingPilot {
    dragged?: boolean;
}

interface SchedulingLoadResultWithTimeRange extends SchedulingLoadResult {
    readonly timeRange: InstantRange;
}
