/* tslint:disable */
import { AreaId, CountryId, IMO, Instant, LocalDate, LocalDateTime, LocalTime, MMSI, PilotStationId, PilotageAreaId, PilotageState } from 'common';

import { Injectable, Provider, Type } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type AirDraft = Branded<number, 'AirDraft'>;
export type BerthCode = Branded<string, 'BerthCode'>;
export type BerthId = Branded<number, 'BerthId'>;
export type BlobRef = Branded<string, 'BlobRef'>;
export type BookingId = Branded<number, 'BookingId'>;
export type BrokerId = Branded<number, 'BrokerId'>;
export type CompanyId = Branded<number, 'CompanyId'>;
export type Draft = Branded<number, 'Draft'>;
export type EmailAddress = Branded<string, 'EmailAddress'>;
export type EndpointId = Branded<number, 'EndpointId'>;
export type Hours = Branded<number, 'Hours'>;
export type JwtToken = Branded<string, 'JwtToken'>;
export type Locode = Branded<string, 'Locode'>;
export type Minutes = Branded<number, 'Minutes'>;
export type Money = Branded<number, 'Money'>;
export type OvtInvoiceAddress = Branded<string, 'OvtInvoiceAddress'>;
export type PecFairwayId = Branded<number, 'PecFairwayId'>;
export type PecTestBookingToken = Branded<string, 'PecTestBookingToken'>;
export type PecTestId = Branded<number, 'PecTestId'>;
export type PhoneNumber = Branded<string, 'PhoneNumber'>;
export type PilotageCommentId = Branded<number, 'PilotageCommentId'>;
export type PilotOnlineLoginToken = Branded<string, 'PilotOnlineLoginToken'>;
export type PilotageId = Branded<number, 'PilotageId'>;
export type PortnetPortCallId = Branded<number, 'PortnetPortCallId'>;
export type SimulatorProviderId = Branded<number, 'SimulatorProviderId'>;
export type SimulatorProviderUserId = Branded<number, 'SimulatorProviderUserId'>;
export type Tonnage = Branded<number, 'Tonnage'>;
export type URI = Branded<string, 'URI'>;
export type Username = Branded<string, 'Username'>;
export type VesselId = Branded<number, 'VesselId'>;
export type VtsZoneId = Branded<number, 'VtsZoneId'>;

export enum BookingState { ESTIMATE = "ESTIMATE", NOTICE = "NOTICE", ORDER = "ORDER", IN_PROGRESS = "IN_PROGRESS", COMPLETED = "COMPLETED", OTHER = "OTHER" }
export enum EndpointType { PILOT_BOARDING_AREA = "PILOT_BOARDING_AREA", HARBOR = "HARBOR", ANCHORAGE = "ANCHORAGE" }
export enum GeneralArea { SEA = "SEA", SAIMAA = "SAIMAA" }
export enum Language { FINNISH = "FINNISH", ENGLISH = "ENGLISH" }
export enum PecType { FAMILIARISATION_VOYAGE = "FAMILIARISATION_VOYAGE", FAIRWAY_KNOWLEDGE_TEST = "FAIRWAY_KNOWLEDGE_TEST", PRACTICAL_PILOTAGE_TEST = "PRACTICAL_PILOTAGE_TEST", SHIP_SIMULATOR_TEST = "SHIP_SIMULATOR_TEST" }
export enum PilotOnlinePermission { IMPERSONATE_BROKER = "IMPERSONATE_BROKER" }
export enum PilotageDirection { INBOUND = "INBOUND", INBOUND_ANCHORAGE = "INBOUND_ANCHORAGE", OUTBOUND = "OUTBOUND", OUTBOUND_ANCHORAGE = "OUTBOUND_ANCHORAGE", TRANSIT = "TRANSIT", PORT_TO_PORT = "PORT_TO_PORT", SHIFT = "SHIFT" }
export enum PortCallPilotageDirection { INBOUND = "INBOUND", OUTBOUND = "OUTBOUND" }
export enum PublicTrafficPortEventType { ARRIVAL = "ARRIVAL", DEPARTURE = "DEPARTURE" }
export enum RegisterAsBrokerResult { SUCCESSFUL_SUBMIT = "SUCCESSFUL_SUBMIT", BROKER_APPLICATION_EXISTS = "BROKER_APPLICATION_EXISTS", BROKER_EXISTS = "BROKER_EXISTS" }
export enum RegisterAsVesselUserResult { SUCCESSFUL_SUBMIT = "SUCCESSFUL_SUBMIT", VESSEL_USER_APPLICATION_EXISTS = "VESSEL_USER_APPLICATION_EXISTS", VESSEL_USER_EXISTS = "VESSEL_USER_EXISTS" }
export enum ResendLoginLinkResult { SUCCESSFUL_RESEND = "SUCCESSFUL_RESEND", USER_NOT_FOUND = "USER_NOT_FOUND", UNKNOWN = "UNKNOWN" }
export enum SimulatorProviderAcceptanceState { NOT_PROCESSED = "NOT_PROCESSED", ACCEPTED = "ACCEPTED", REJECTED = "REJECTED" }
export enum TugOrderStatus { UNORDERED = "UNORDERED", ORDERED = "ORDERED" }
export enum TugsRequested { NONE = "NONE", ONE = "ONE", TWO = "TWO", THREE = "THREE", FOUR = "FOUR", ONE_CONV = "ONE_CONV", ONE_ASD = "ONE_ASD", TWO_CONV = "TWO_CONV", TWO_ASD = "TWO_ASD", ONE_CONV_ONE_ASD = "ONE_CONV_ONE_ASD", THREE_CONV = "THREE_CONV", THREE_ASD = "THREE_ASD", TWO_CONV_ONE_ASD = "TWO_CONV_ONE_ASD", ONE_CONV_TWO_ASD = "ONE_CONV_TWO_ASD", FOUR_CONV = "FOUR_CONV", FOUR_ASD = "FOUR_ASD", ONE_CONV_THREE_ASD = "ONE_CONV_THREE_ASD", TWO_COND_TWO_ASD = "TWO_COND_TWO_ASD", THREE_CONV_ONE_ASD = "THREE_CONV_ONE_ASD" }

export interface ActiveBooking {
    airDraft: AirDraft | null;
    bookingId: BookingId;
    brokerCompanyId: CompanyId | null;
    currentUserFollowsPilotage: boolean;
    direction: PilotageDirection | null;
    draft: Draft | null;
    draftAft: Draft | null;
    draftFore: Draft | null;
    draftGiven: Instant | null;
    endBerth: string | null;
    endBerthId: number | null;
    endLocationCode: Locode | null;
    endLocationId: EndpointId | null;
    endLocationName: string | null;
    endLocationWithinHarbor: boolean;
    endTime: Instant | null;
    etaUpdated: Instant | null;
    hasNotableComment: boolean;
    hasNotifications: boolean;
    hasPilotageBeenCompleted: boolean;
    lastKnownLocation: GeoPoint | null;
    pilotageHasStarted: boolean;
    pilotageId: PilotageId | null;
    portCallPair: PortCallInfo | null;
    primaryPilotageState: PilotageState;
    primaryState: BookingState;
    saimaaOrCanal: boolean;
    scheduleChangeAllowed: boolean;
    scheduleSource: string | null;
    showIndications: boolean;
    startBerth: string | null;
    startLocationCode: Locode | null;
    startLocationId: EndpointId | null;
    startLocationName: string | null;
    startLocationWithinHarbor: boolean;
    startTime: Instant;
    stateChange: StateChangeAction;
    suggestedPilotageState: BookingState | null;
    suggestedStartTime: Instant | null;
    termsOfServiceInfractions: string[];
    tosDraftApproval: PilotageDraftApproval | null;
    tugOrderStatus: TugOrderStatus | null;
    tugsRequested: TugsRequested | null;
    unreadCommentCount: number;
    vesselEmail: EmailAddress | null;
    vesselEta: Instant;
    vesselId: VesselId;
    vesselMandate: boolean;
    vesselMmsi: MMSI | null;
    vesselName: string;
    warnings: Warning[];
}

export interface AdvanceStateDto {
    drafts: Drafts | null;
    message: string | null;
    startTime: Instant;
    tugOrderStatus: TugOrderStatus | null;
    tugsRequested: TugsRequested | null;
}

export interface ApplicationForBrokerRegistration {
    email: EmailAddress;
    firstName: string;
    lastName: string;
    organization: string;
    phone: PhoneNumber;
}

export interface ApplicationForVesselUserRegistration {
    additionalInfo: string;
    email: EmailAddress;
    imo: IMO | null;
    name: string;
}

export interface AreaInfo {
    id: AreaId;
    name: string;
    pilotageAreas: PilotageAreaInfo[];
}

export interface BerthInfo {
    code: BerthCode;
    harborId: EndpointId;
    id: BerthId;
    name: string | null;
}

export interface BookingActivityEvent {
    details: BookingActivityValue[];
    message: BookingActivityMessage | null;
    summary: string | null;
    time: Instant;
}

export interface BookingActivityMessage {
    author: string;
    finnpilotAuthor: boolean;
    id: PilotageCommentId;
    message: string;
    messageReadBy: string;
    messageReadBySomeoneInteresting: boolean;
}

export interface BookingActivityValue {
    formattedValue: string;
    summary: string;
}

export interface BookingDetails {
    events: BookingActivityEvent[];
}

export interface BookingVesselDetails {
    actualDraft: Draft | null;
    airDraft: AirDraft | null;
    bookingId: BookingId;
    draftAft: Draft | null;
    draftFore: Draft | null;
    endLocationCode: Locode | null;
    netTonnage: Tonnage | null;
    pilotOnlineUser: boolean;
    startLocationCode: Locode | null;
    startTime: Instant;
    vesselDraft: Draft | null;
    vesselEmail: EmailAddress | null;
    vesselImo: IMO | null;
    vesselMandate: boolean;
    vesselName: string;
    vesselPhone: string | null;
}

export interface BrokerProfile {
    companyId: CompanyId;
    email: EmailAddress;
    firstName: string;
    lastName: string;
    organizationName: string;
    phone: PhoneNumber | null;
}

export interface CompanyInfo {
    customerNumber: number;
    id: CompanyId;
    name: string;
    removed: boolean;
    vatId: string | null;
}

export interface CountryInfo {
    id: CountryId;
    name: string;
}

export interface CreateBookingDto {
    actualDraft: Draft | null;
    brokerCompanyId: CompanyId | null;
    draftAft: Draft | null;
    draftFore: Draft | null;
    end: EndpointId | null;
    endBerth: BerthId | null;
    message: string | null;
    start: EndpointId | null;
    startBerth: BerthId | null;
    startTime: Instant;
    vesselEmail: EmailAddress | null;
    vesselId: VesselId;
    vesselMandate: boolean | null;
    vesselPhone: string | null;
}

export interface CreateOutboundBookingDto {
    actualDraft: Draft | null;
    brokerCompanyId: CompanyId;
    draftAft: Draft | null;
    draftFore: Draft | null;
    end: EndpointId | null;
    endBerth: BerthId | null;
    message: string | null;
    start: EndpointId;
    startBerth: BerthId | null;
    startTime: Instant;
    vesselId: VesselId;
}

export interface CurrentAisData {
    locationUpdatesTopic: string;
    metadataUpdatesTopic: string;
    vessels: VesselAisData[];
}

export interface DraftBounds {
    max: Draft | null;
    min: Draft | null;
}

export interface Drafts {
    aft: Draft | null;
    air: AirDraft | null;
    fore: Draft | null;
    max: Draft | null;
}

export interface EditBookingVesselDetails {
    drafts: Drafts | null;
    vesselEmail: EmailAddress | null;
    vesselMandate: boolean | null;
    vesselPhone: string | null;
}

export interface EditBrokerProfile {
    data: EditableBrokerData;
    organizationName: string;
}

export interface EditableBrokerData {
    email: EmailAddress;
    firstName: string;
    lastName: string;
    notificationEmail: EmailAddress | null;
    phone: PhoneNumber | null;
    smsNotificationEnabled: boolean;
}

export interface EditableVesselData {
    email: EmailAddress;
    notificationsEnabled: boolean;
}

export interface FairwayKnowledgeReservationDetails {
    billingData: StandalonePecBillingEditData;
    fairwayIds: PecFairwayId[];
    location: FairwayKnowledgeTestLocation;
    notes: string;
    orderTime: Instant;
    orderTime2: Instant | null;
    orderTime3: Instant | null;
    pilotagePlanRef: BlobRef;
    vesselId: VesselId;
    vtsZoneIds: VtsZoneId[];
}

export interface FeedbackDto {
    contactInformation: string;
    feedback: string;
}

export interface FindCompanyInfo {
    id: CompanyId;
    name: string;
    vatId: string | null;
}

export interface FindVesselsResults {
    totalResults: number;
    vessels: PublicVesselInfo[];
}

export interface GeoPoint {
    lat: number;
    lng: number;
}

export interface InitiateBookingResponse {
    confirmedLink: string | null;
}

export interface NetTonnageClassInfo {
    min: Tonnage;
    title: string;
}

export interface NewBookingDetails {
    companyName: string;
    vessel: VesselDetails;
}

export interface NewBrokerCommentDto {
    message: string;
}

export interface OAuthLoginSettings {
    clientId: string;
    tenantId: string;
}

export interface PecBillingData {
    addressLine1: string;
    addressLine2: string;
    businessId: string;
    countryName: string | null;
    eInvoiceAddress: OvtInvoiceAddress | null;
    invoicingEmail: EmailAddress | null;
    organizationName: string;
}

export interface PecBookingCandidateData {
    email: EmailAddress | null;
    name: string | null;
    phone: PhoneNumber | null;
}

export interface PecFairwayInfo {
    estimatedDuration: Minutes;
    finnishName: string;
    id: PecFairwayId;
    name: string;
}

export interface PilotOnlineMessage {
    content: string;
    dismissId: string | null;
    link: URI | null;
}

export interface PilotStationInfo {
    areaId: AreaId;
    availableForPecTests: boolean;
    geographicalOrder: number;
    id: PilotStationId;
    mainStationId: PilotStationId | null;
    name: string;
    pilotageAreaId: PilotageAreaId;
    trafficLogStation: boolean;
}

export interface PilotageAreaInfo {
    areaId: AreaId;
    geographicalOrder: number;
    id: PilotageAreaId;
    name: string;
    pilotStations: PilotStationInfo[];
}

export interface PilotageDraftApproval {
    author: Username;
    reason: string;
    time: Instant;
}

export interface PilotageOverview {
    direction: PilotageDirection;
    id: PilotageId;
    startTime: Instant;
}

export interface PilotagePlanUploadData {
    blobRef: BlobRef;
    uploadUrl: string;
}

export interface PilotagePriceCalculatorData {
    routes: PilotagePriceCalculatorRoute[];
    saimaaNetTonnageClasses: NetTonnageClassInfo[];
    seaNetTonnageClasses: NetTonnageClassInfo[];
}

export interface PilotagePriceCalculatorRoute {
    chain: boolean;
    endLocationCode: Locode;
    endLocationName: string;
    generalArea: GeneralArea;
    id: number;
    length: number;
    startLocationCode: Locode;
    startLocationName: string;
}

export interface PilotagePriceData {
    baseFee: Money;
    mileFee: Money;
    miles: number;
    pilotages: number;
    total: Money;
    validityStartDate: LocalDate;
}

export interface Port {
    code: Locode;
    id: number;
    name: string | null;
    nameWithCode: string;
}

export interface PortCallInfo {
    direction: PortCallPilotageDirection;
    id: BookingId;
    startTime: Instant;
}

export interface PortHub {
    id: number;
    name: string;
    ports: Port[];
}

export interface PortsDto {
    hubs: PortHub[];
    portsWithoutHub: Port[];
}

export interface PublicTrafficInfo {
    pilotages: PublicTrafficInfoPilotage[];
    portCallArrivals: PublicTrafficInfoPortCall[];
    portCallDepartures: PublicTrafficInfoPortCall[];
    portEvents: PublicTrafficInfoPortEvent[];
    timestamp: Instant;
}

export interface PublicTrafficInfoPilotage {
    active: boolean;
    bookingId: PilotageId | null;
    direction: PilotageDirection;
    draft: Draft | null;
    endBerth: BerthCode | null;
    endTime: Instant;
    etaHours: number | null;
    id: PilotageId;
    inVesselNoticeState: boolean;
    legs: PublicTrafficInfoPilotageLeg[];
    pair: PilotageOverview | null;
    relatedEndpointCodes: Locode[];
    relatedPilotStations: PilotStationId[];
    routeEnd: RouteEndpointInfo;
    routeStart: RouteEndpointInfo;
    scheduleSource: string | null;
    shipAgentName: string | null;
    startBerth: BerthCode | null;
    startTime: Instant;
    state: PilotageState;
    updated: Instant;
    vessel: PublicTrafficLogVesselInfo;
}

export interface PublicTrafficInfoPilotageLeg {
    bookingId: PilotageId | null;
    endTime: Instant;
    pilotageId: PilotageId;
    routeEnd: RouteEndpointInfo;
    routeStart: RouteEndpointInfo;
    startTime: Instant;
    state: PilotageState;
}

export interface PublicTrafficInfoPortCall {
    active: boolean;
    arrivalCompleted: boolean;
    arrivalLastUpdated: Instant | null;
    arrivalScheduleSource: string | null;
    arrivalTime: Instant | null;
    arrivalTimeFromPortnet: boolean;
    arrivalTimeTooltip: string;
    berth: string | null;
    departureCompleted: boolean;
    departureLastUpdated: Instant | null;
    departureScheduleSource: string | null;
    departureTime: Instant | null;
    departureTimeFromPortnet: boolean;
    departureTimeTooltip: string;
    id: string;
    inboundBookingId: PilotageId | null;
    inboundPilotage: PublicTrafficInfoPortCallPilotage | null;
    outboundBookingId: PilotageId | null;
    outboundPilotage: PublicTrafficInfoPortCallPilotage | null;
    portCode: Locode;
    portName: string;
    portNameFromPortnet: boolean;
    relatedEndpointCodes: Locode[];
    relatedPilotStations: PilotStationId[];
    type: string;
    vessel: PublicTrafficLogVesselInfo;
}

export interface PublicTrafficInfoPortCallPilotage {
    id: PilotageId;
    state: PilotageState;
}

export interface PublicTrafficInfoPortEvent {
    active: boolean;
    berth: string | null;
    bookingId: PilotageId | null;
    brokerCompanyId: CompanyId | null;
    id: string;
    pilotageId: PilotageId | null;
    pilotageState: PilotageState | null;
    portCode: Locode;
    portName: string;
    portNameFromPortnet: boolean;
    relatedEndpointCodes: Locode[];
    relatedPilotStations: PilotStationId[];
    scheduleSource: string | null;
    time: Instant;
    timeFromPortnet: boolean;
    timeLastUpdated: Instant | null;
    timeTooltip: string | null;
    type: PublicTrafficPortEventType;
    vessel: PublicTrafficLogVesselInfo;
}

export interface PublicTrafficLogVesselInfo {
    callSign: string | null;
    id: VesselId | null;
    imo: IMO | null;
    lastKnownVesselLocation: GeoPoint | null;
    mmsi: MMSI | null;
    name: string;
}

export interface PublicVesselInfo {
    callSign: string | null;
    draft: Draft | null;
    id: VesselId;
    imo: IMO | null;
    mmsi: MMSI | null;
    name: string;
    netTonnage: Tonnage | null;
}

export interface ReferenceData {
    allPilotStations: PilotStationInfo[];
    areas: AreaInfo[];
    pilotageAreas: PilotageAreaInfo[];
}

export interface ResendLoginLink {
    email: string;
}

export interface ReserverSimulatorSlot {
    test: SimulatorTestAcceptanceInfo;
    time: LocalDateTime;
}

export interface RouteEndpointInfo {
    code: Locode;
    defaultInboundDuration: Minutes;
    id: EndpointId;
    name: string;
    type: EndpointType;
}

export interface RouteSearchResultRow {
    endCode: Locode | null;
    endId: EndpointId | null;
    endName: string | null;
    endType: EndpointType | null;
    id: string;
    label: string;
    startCode: Locode | null;
    startId: EndpointId | null;
    startName: string | null;
}

export interface RouteStartResults {
    routes: RouteSearchResultRow[];
    startLocationName: string;
}

export interface ShipSimulatorTestReservationDetails {
    billingData: StandalonePecBillingEditData;
    estimatedHours: Hours;
    fairwayIds: PecFairwayId[];
    notes: string;
    orderTime: LocalDateTime;
    simulatorProviderId: SimulatorProviderId;
    vesselId: VesselId;
}

export interface SimulatorProviderFairways {
    fairwayIds: PecFairwayId[];
}

export interface SimulatorProviderInfo {
    fairwayIds: PecFairwayId[];
    id: SimulatorProviderId;
    name: string;
    timeslots: LocalDateTime[];
}

export interface SimulatorProviderUserEditData {
    email: EmailAddress;
    name: string;
}

export interface SimulatorProviderUserInfo {
    email: EmailAddress;
    id: SimulatorProviderUserId;
    name: string;
}

export interface SimulatorSlotUpdate {
    available: boolean;
    slots: LocalDateTime[];
}

export interface SimulatorSlotsServerState {
    availableSlots: LocalDateTime[];
    reservedSlots: ReserverSimulatorSlot[];
}

export interface SimulatorTestAcceptanceInfo {
    acceptanceState: SimulatorProviderAcceptanceState;
    billingData: PecBillingData;
    candidate: PecBookingCandidateData;
    endTime: Instant;
    fairwayNames: string;
    startTime: Instant;
    testId: PecTestId;
    vesselName: string;
}

export interface SimulatorTestRejectDto {
    reason: string;
}

export interface StandalonePecBillingEditData {
    addressLine1: string;
    addressLine2: string;
    businessId: string;
    countryId: CountryId;
    eInvoiceAddress: OvtInvoiceAddress | null;
    email: EmailAddress;
    organizationName: string;
}

export interface StandalonePecData {
    candidate: PecBookingCandidateData;
    fairwayTestReservationDetails: FairwayKnowledgeReservationDetails | null;
    shipSimulatorTestReservationDetails: ShipSimulatorTestReservationDetails | null;
    type: PecType;
}

export interface TugsRequestedInfo {
    selectionLabel: string;
    totalTugs: number;
    value: TugsRequested;
}

export interface UpdateScheduleDto {
    message: string | null;
    startTime: Instant;
}

export interface VesselAisData {
    lastUpdateTime: Instant;
    location: VesselLocationEvent | null;
    metadata: VesselMetadataEvent | null;
    mmsi: MMSI;
}

export interface VesselDetails {
    beam: number | null;
    callSign: string | null;
    capacity: Tonnage | null;
    draft: Draft | null;
    draftBounds: VesselDraftBounds;
    email: EmailAddress | null;
    enginePower: string | null;
    grossTonnage: Tonnage | null;
    homeHarbor: string | null;
    iceClass: string | null;
    id: VesselId;
    imo: IMO | null;
    manufacturingYear: number | null;
    maxHeight: number | null;
    maxLength: number | null;
    measuredLength: number | null;
    mmsi: MMSI | null;
    name: string;
    nationality: string | null;
    netTonnage: Tonnage | null;
    phoneNumber: string | null;
    phoneNumber2: string | null;
    pilotonlineUser: boolean;
    propellerCount: string | null;
    propellerType: string | null;
    rudderCount: string | null;
    rudderPropellers: string | null;
    rudderType: string | null;
    speed: number | null;
    vesselClass: string | null;
}

export interface VesselDraftBounds {
    iceDraftAft: DraftBounds;
    iceDraftFore: DraftBounds;
    maxDraft: DraftBounds;
}

export interface VesselLocationEvent {
    cog: number | null;
    heading: number | null;
    mmsi: MMSI;
    navStat: number;
    point: GeoPoint;
    posAcc: boolean;
    rot: number | null;
    sog: number | null;
    timestamp: Instant;
}

export interface VesselMetadataEvent {
    destination: string | null;
    mmsi: MMSI;
    name: string | null;
    shipType: number;
}

export interface VtsZoneInfo {
    fairways: PecFairwayInfo[];
    id: VtsZoneId;
    name: string;
}

export interface Warning {
    text: string;
    time: Instant;
}

export interface Broker {
    id: BrokerId;
    jwtToken: JwtToken;
    profile: BrokerProfile;
}

export interface Internal {
    jwtToken: JwtToken;
    permissions: PilotOnlinePermission[];
}

export interface MakeNotice {
    enabled: boolean;
    highlight: boolean;
    longText: string;
    needsDraft: boolean;
    needsEtaConfirmation: boolean;
    scheduleStyle: string;
    text: string;
}

export interface MakeOrder {
    enabled: boolean;
    highlight: boolean;
    longText: string;
    needsDraft: boolean;
    needsEtaConfirmation: boolean;
    scheduleStyle: string;
    text: string;
}

export interface NoAction {
    enabled: boolean;
    highlight: boolean;
    longText: string;
    needsDraft: boolean;
    needsEtaConfirmation: boolean;
    scheduleStyle: string;
    text: string;
}

export interface OnBoard {
}

export interface OnPilotStation {
    pilotStationId: PilotStationId;
}

export interface Other {
    description: string;
}

export interface SimulatorProviderUser {
    id: SimulatorProviderUserId;
    jwtToken: JwtToken;
}

export interface Vessel {
    id: VesselId;
    jwtToken: JwtToken;
}

export interface FairwayKnowledgeTestLocation_OnBoard extends OnBoard {
    type: 'OnBoard';
}


export interface FairwayKnowledgeTestLocation_OnPilotStation extends OnPilotStation {
    type: 'OnPilotStation';
}


export interface FairwayKnowledgeTestLocation_Other extends Other {
    type: 'Other';
}


export type FairwayKnowledgeTestLocation = FairwayKnowledgeTestLocation_OnBoard | FairwayKnowledgeTestLocation_OnPilotStation | FairwayKnowledgeTestLocation_Other;

export interface LoginResponse_Broker extends Broker {
    type: 'Broker';
}


export interface LoginResponse_Internal extends Internal {
    type: 'Internal';
}


export interface LoginResponse_SimulatorProviderUser extends SimulatorProviderUser {
    type: 'SimulatorProviderUser';
}


export interface LoginResponse_Vessel extends Vessel {
    type: 'Vessel';
}


export type LoginResponse = LoginResponse_Broker | LoginResponse_Internal | LoginResponse_SimulatorProviderUser | LoginResponse_Vessel;

export interface StateChangeAction_MakeNotice extends MakeNotice {
    type: 'MakeNotice';
}


export interface StateChangeAction_MakeOrder extends MakeOrder {
    type: 'MakeOrder';
}


export interface StateChangeAction_NoAction extends NoAction {
    type: 'NoAction';
}


export type StateChangeAction = StateChangeAction_MakeNotice | StateChangeAction_MakeOrder | StateChangeAction_NoAction;

function registerDefaultSerializers(config: ApinaConfig): void {
    config.registerIdentitySerializer('AirDraft');
    config.registerIdentitySerializer('BerthCode');
    config.registerIdentitySerializer('BerthId');
    config.registerIdentitySerializer('BlobRef');
    config.registerIdentitySerializer('BookingId');
    config.registerIdentitySerializer('BrokerId');
    config.registerIdentitySerializer('CompanyId');
    config.registerIdentitySerializer('Draft');
    config.registerIdentitySerializer('EmailAddress');
    config.registerIdentitySerializer('EndpointId');
    config.registerIdentitySerializer('Hours');
    config.registerIdentitySerializer('JwtToken');
    config.registerIdentitySerializer('Locode');
    config.registerIdentitySerializer('Minutes');
    config.registerIdentitySerializer('Money');
    config.registerIdentitySerializer('OvtInvoiceAddress');
    config.registerIdentitySerializer('PecFairwayId');
    config.registerIdentitySerializer('PecTestBookingToken');
    config.registerIdentitySerializer('PecTestId');
    config.registerIdentitySerializer('PhoneNumber');
    config.registerIdentitySerializer('PilotageCommentId');
    config.registerIdentitySerializer('PilotOnlineLoginToken');
    config.registerIdentitySerializer('PilotageId');
    config.registerIdentitySerializer('PortnetPortCallId');
    config.registerIdentitySerializer('SimulatorProviderId');
    config.registerIdentitySerializer('SimulatorProviderUserId');
    config.registerIdentitySerializer('Tonnage');
    config.registerIdentitySerializer('URI');
    config.registerIdentitySerializer('Username');
    config.registerIdentitySerializer('VesselId');
    config.registerIdentitySerializer('VtsZoneId');

    config.registerIdentitySerializer('BookingState');
    config.registerIdentitySerializer('EndpointType');
    config.registerIdentitySerializer('GeneralArea');
    config.registerIdentitySerializer('Language');
    config.registerIdentitySerializer('PecType');
    config.registerIdentitySerializer('PilotOnlinePermission');
    config.registerIdentitySerializer('PilotageDirection');
    config.registerIdentitySerializer('PortCallPilotageDirection');
    config.registerIdentitySerializer('PublicTrafficPortEventType');
    config.registerIdentitySerializer('RegisterAsBrokerResult');
    config.registerIdentitySerializer('RegisterAsVesselUserResult');
    config.registerIdentitySerializer('ResendLoginLinkResult');
    config.registerIdentitySerializer('SimulatorProviderAcceptanceState');
    config.registerIdentitySerializer('TugOrderStatus');
    config.registerIdentitySerializer('TugsRequested');

    config.registerClassSerializer<ActiveBooking>('ActiveBooking', {
        'airDraft': 'AirDraft',
        'bookingId': 'BookingId',
        'brokerCompanyId': 'CompanyId',
        'currentUserFollowsPilotage': 'boolean',
        'direction': 'PilotageDirection',
        'draft': 'Draft',
        'draftAft': 'Draft',
        'draftFore': 'Draft',
        'draftGiven': 'Instant',
        'endBerth': 'string',
        'endBerthId': 'number',
        'endLocationCode': 'Locode',
        'endLocationId': 'EndpointId',
        'endLocationName': 'string',
        'endLocationWithinHarbor': 'boolean',
        'endTime': 'Instant',
        'etaUpdated': 'Instant',
        'hasNotableComment': 'boolean',
        'hasNotifications': 'boolean',
        'hasPilotageBeenCompleted': 'boolean',
        'lastKnownLocation': 'GeoPoint',
        'pilotageHasStarted': 'boolean',
        'pilotageId': 'PilotageId',
        'portCallPair': 'PortCallInfo',
        'primaryPilotageState': 'PilotageState',
        'primaryState': 'BookingState',
        'saimaaOrCanal': 'boolean',
        'scheduleChangeAllowed': 'boolean',
        'scheduleSource': 'string',
        'showIndications': 'boolean',
        'startBerth': 'string',
        'startLocationCode': 'Locode',
        'startLocationId': 'EndpointId',
        'startLocationName': 'string',
        'startLocationWithinHarbor': 'boolean',
        'startTime': 'Instant',
        'stateChange': 'StateChangeAction',
        'suggestedPilotageState': 'BookingState',
        'suggestedStartTime': 'Instant',
        'termsOfServiceInfractions': 'string[]',
        'tosDraftApproval': 'PilotageDraftApproval',
        'tugOrderStatus': 'TugOrderStatus',
        'tugsRequested': 'TugsRequested',
        'unreadCommentCount': 'number',
        'vesselEmail': 'EmailAddress',
        'vesselEta': 'Instant',
        'vesselId': 'VesselId',
        'vesselMandate': 'boolean',
        'vesselMmsi': 'MMSI',
        'vesselName': 'string',
        'warnings': 'Warning[]'
    });

    config.registerClassSerializer<AdvanceStateDto>('AdvanceStateDto', {
        'drafts': 'Drafts',
        'message': 'string',
        'startTime': 'Instant',
        'tugOrderStatus': 'TugOrderStatus',
        'tugsRequested': 'TugsRequested'
    });

    config.registerClassSerializer<ApplicationForBrokerRegistration>('ApplicationForBrokerRegistration', {
        'email': 'EmailAddress',
        'firstName': 'string',
        'lastName': 'string',
        'organization': 'string',
        'phone': 'PhoneNumber'
    });

    config.registerClassSerializer<ApplicationForVesselUserRegistration>('ApplicationForVesselUserRegistration', {
        'additionalInfo': 'string',
        'email': 'EmailAddress',
        'imo': 'IMO',
        'name': 'string'
    });

    config.registerClassSerializer<AreaInfo>('AreaInfo', {
        'id': 'AreaId',
        'name': 'string',
        'pilotageAreas': 'PilotageAreaInfo[]'
    });

    config.registerClassSerializer<BerthInfo>('BerthInfo', {
        'code': 'BerthCode',
        'harborId': 'EndpointId',
        'id': 'BerthId',
        'name': 'string'
    });

    config.registerClassSerializer<BookingActivityEvent>('BookingActivityEvent', {
        'details': 'BookingActivityValue[]',
        'message': 'BookingActivityMessage',
        'summary': 'string',
        'time': 'Instant'
    });

    config.registerClassSerializer<BookingActivityMessage>('BookingActivityMessage', {
        'author': 'string',
        'finnpilotAuthor': 'boolean',
        'id': 'PilotageCommentId',
        'message': 'string',
        'messageReadBy': 'string',
        'messageReadBySomeoneInteresting': 'boolean'
    });

    config.registerClassSerializer<BookingActivityValue>('BookingActivityValue', {
        'formattedValue': 'string',
        'summary': 'string'
    });

    config.registerClassSerializer<BookingDetails>('BookingDetails', {
        'events': 'BookingActivityEvent[]'
    });

    config.registerClassSerializer<BookingVesselDetails>('BookingVesselDetails', {
        'actualDraft': 'Draft',
        'airDraft': 'AirDraft',
        'bookingId': 'BookingId',
        'draftAft': 'Draft',
        'draftFore': 'Draft',
        'endLocationCode': 'Locode',
        'netTonnage': 'Tonnage',
        'pilotOnlineUser': 'boolean',
        'startLocationCode': 'Locode',
        'startTime': 'Instant',
        'vesselDraft': 'Draft',
        'vesselEmail': 'EmailAddress',
        'vesselImo': 'IMO',
        'vesselMandate': 'boolean',
        'vesselName': 'string',
        'vesselPhone': 'string'
    });

    config.registerClassSerializer<BrokerProfile>('BrokerProfile', {
        'companyId': 'CompanyId',
        'email': 'EmailAddress',
        'firstName': 'string',
        'lastName': 'string',
        'organizationName': 'string',
        'phone': 'PhoneNumber'
    });

    config.registerClassSerializer<CompanyInfo>('CompanyInfo', {
        'customerNumber': 'number',
        'id': 'CompanyId',
        'name': 'string',
        'removed': 'boolean',
        'vatId': 'string'
    });

    config.registerClassSerializer<CountryInfo>('CountryInfo', {
        'id': 'CountryId',
        'name': 'string'
    });

    config.registerClassSerializer<CreateBookingDto>('CreateBookingDto', {
        'actualDraft': 'Draft',
        'brokerCompanyId': 'CompanyId',
        'draftAft': 'Draft',
        'draftFore': 'Draft',
        'end': 'EndpointId',
        'endBerth': 'BerthId',
        'message': 'string',
        'start': 'EndpointId',
        'startBerth': 'BerthId',
        'startTime': 'Instant',
        'vesselEmail': 'EmailAddress',
        'vesselId': 'VesselId',
        'vesselMandate': 'boolean',
        'vesselPhone': 'string'
    });

    config.registerClassSerializer<CreateOutboundBookingDto>('CreateOutboundBookingDto', {
        'actualDraft': 'Draft',
        'brokerCompanyId': 'CompanyId',
        'draftAft': 'Draft',
        'draftFore': 'Draft',
        'end': 'EndpointId',
        'endBerth': 'BerthId',
        'message': 'string',
        'start': 'EndpointId',
        'startBerth': 'BerthId',
        'startTime': 'Instant',
        'vesselId': 'VesselId'
    });

    config.registerClassSerializer<CurrentAisData>('CurrentAisData', {
        'locationUpdatesTopic': 'string',
        'metadataUpdatesTopic': 'string',
        'vessels': 'VesselAisData[]'
    });

    config.registerClassSerializer<DraftBounds>('DraftBounds', {
        'max': 'Draft',
        'min': 'Draft'
    });

    config.registerClassSerializer<Drafts>('Drafts', {
        'aft': 'Draft',
        'air': 'AirDraft',
        'fore': 'Draft',
        'max': 'Draft'
    });

    config.registerClassSerializer<EditBookingVesselDetails>('EditBookingVesselDetails', {
        'drafts': 'Drafts',
        'vesselEmail': 'EmailAddress',
        'vesselMandate': 'boolean',
        'vesselPhone': 'string'
    });

    config.registerClassSerializer<EditBrokerProfile>('EditBrokerProfile', {
        'data': 'EditableBrokerData',
        'organizationName': 'string'
    });

    config.registerClassSerializer<EditableBrokerData>('EditableBrokerData', {
        'email': 'EmailAddress',
        'firstName': 'string',
        'lastName': 'string',
        'notificationEmail': 'EmailAddress',
        'phone': 'PhoneNumber',
        'smsNotificationEnabled': 'boolean'
    });

    config.registerClassSerializer<EditableVesselData>('EditableVesselData', {
        'email': 'EmailAddress',
        'notificationsEnabled': 'boolean'
    });

    config.registerClassSerializer<FairwayKnowledgeReservationDetails>('FairwayKnowledgeReservationDetails', {
        'billingData': 'StandalonePecBillingEditData',
        'fairwayIds': 'PecFairwayId[]',
        'location': 'FairwayKnowledgeTestLocation',
        'notes': 'string',
        'orderTime': 'Instant',
        'orderTime2': 'Instant',
        'orderTime3': 'Instant',
        'pilotagePlanRef': 'BlobRef',
        'vesselId': 'VesselId',
        'vtsZoneIds': 'VtsZoneId[]'
    });

    config.registerClassSerializer<FeedbackDto>('FeedbackDto', {
        'contactInformation': 'string',
        'feedback': 'string'
    });

    config.registerClassSerializer<FindCompanyInfo>('FindCompanyInfo', {
        'id': 'CompanyId',
        'name': 'string',
        'vatId': 'string'
    });

    config.registerClassSerializer<FindVesselsResults>('FindVesselsResults', {
        'totalResults': 'number',
        'vessels': 'PublicVesselInfo[]'
    });

    config.registerClassSerializer<GeoPoint>('GeoPoint', {
        'lat': 'number',
        'lng': 'number'
    });

    config.registerClassSerializer<InitiateBookingResponse>('InitiateBookingResponse', {
        'confirmedLink': 'string'
    });

    config.registerClassSerializer<NetTonnageClassInfo>('NetTonnageClassInfo', {
        'min': 'Tonnage',
        'title': 'string'
    });

    config.registerClassSerializer<NewBookingDetails>('NewBookingDetails', {
        'companyName': 'string',
        'vessel': 'VesselDetails'
    });

    config.registerClassSerializer<NewBrokerCommentDto>('NewBrokerCommentDto', {
        'message': 'string'
    });

    config.registerClassSerializer<OAuthLoginSettings>('OAuthLoginSettings', {
        'clientId': 'string',
        'tenantId': 'string'
    });

    config.registerClassSerializer<PecBillingData>('PecBillingData', {
        'addressLine1': 'string',
        'addressLine2': 'string',
        'businessId': 'string',
        'countryName': 'string',
        'eInvoiceAddress': 'OvtInvoiceAddress',
        'invoicingEmail': 'EmailAddress',
        'organizationName': 'string'
    });

    config.registerClassSerializer<PecBookingCandidateData>('PecBookingCandidateData', {
        'email': 'EmailAddress',
        'name': 'string',
        'phone': 'PhoneNumber'
    });

    config.registerClassSerializer<PecFairwayInfo>('PecFairwayInfo', {
        'estimatedDuration': 'Minutes',
        'finnishName': 'string',
        'id': 'PecFairwayId',
        'name': 'string'
    });

    config.registerClassSerializer<PilotOnlineMessage>('PilotOnlineMessage', {
        'content': 'string',
        'dismissId': 'string',
        'link': 'URI'
    });

    config.registerClassSerializer<PilotStationInfo>('PilotStationInfo', {
        'areaId': 'AreaId',
        'availableForPecTests': 'boolean',
        'geographicalOrder': 'number',
        'id': 'PilotStationId',
        'mainStationId': 'PilotStationId',
        'name': 'string',
        'pilotageAreaId': 'PilotageAreaId',
        'trafficLogStation': 'boolean'
    });

    config.registerClassSerializer<PilotageAreaInfo>('PilotageAreaInfo', {
        'areaId': 'AreaId',
        'geographicalOrder': 'number',
        'id': 'PilotageAreaId',
        'name': 'string',
        'pilotStations': 'PilotStationInfo[]'
    });

    config.registerClassSerializer<PilotageDraftApproval>('PilotageDraftApproval', {
        'author': 'Username',
        'reason': 'string',
        'time': 'Instant'
    });

    config.registerClassSerializer<PilotageOverview>('PilotageOverview', {
        'direction': 'PilotageDirection',
        'id': 'PilotageId',
        'startTime': 'Instant'
    });

    config.registerClassSerializer<PilotagePlanUploadData>('PilotagePlanUploadData', {
        'blobRef': 'BlobRef',
        'uploadUrl': 'string'
    });

    config.registerClassSerializer<PilotagePriceCalculatorData>('PilotagePriceCalculatorData', {
        'routes': 'PilotagePriceCalculatorRoute[]',
        'saimaaNetTonnageClasses': 'NetTonnageClassInfo[]',
        'seaNetTonnageClasses': 'NetTonnageClassInfo[]'
    });

    config.registerClassSerializer<PilotagePriceCalculatorRoute>('PilotagePriceCalculatorRoute', {
        'chain': 'boolean',
        'endLocationCode': 'Locode',
        'endLocationName': 'string',
        'generalArea': 'GeneralArea',
        'id': 'number',
        'length': 'number',
        'startLocationCode': 'Locode',
        'startLocationName': 'string'
    });

    config.registerClassSerializer<PilotagePriceData>('PilotagePriceData', {
        'baseFee': 'Money',
        'mileFee': 'Money',
        'miles': 'number',
        'pilotages': 'number',
        'total': 'Money',
        'validityStartDate': 'LocalDate'
    });

    config.registerClassSerializer<Port>('Port', {
        'code': 'Locode',
        'id': 'number',
        'name': 'string',
        'nameWithCode': 'string'
    });

    config.registerClassSerializer<PortCallInfo>('PortCallInfo', {
        'direction': 'PortCallPilotageDirection',
        'id': 'BookingId',
        'startTime': 'Instant'
    });

    config.registerClassSerializer<PortHub>('PortHub', {
        'id': 'number',
        'name': 'string',
        'ports': 'Port[]'
    });

    config.registerClassSerializer<PortsDto>('PortsDto', {
        'hubs': 'PortHub[]',
        'portsWithoutHub': 'Port[]'
    });

    config.registerClassSerializer<PublicTrafficInfo>('PublicTrafficInfo', {
        'pilotages': 'PublicTrafficInfoPilotage[]',
        'portCallArrivals': 'PublicTrafficInfoPortCall[]',
        'portCallDepartures': 'PublicTrafficInfoPortCall[]',
        'portEvents': 'PublicTrafficInfoPortEvent[]',
        'timestamp': 'Instant'
    });

    config.registerClassSerializer<PublicTrafficInfoPilotage>('PublicTrafficInfoPilotage', {
        'active': 'boolean',
        'bookingId': 'PilotageId',
        'direction': 'PilotageDirection',
        'draft': 'Draft',
        'endBerth': 'BerthCode',
        'endTime': 'Instant',
        'etaHours': 'number',
        'id': 'PilotageId',
        'inVesselNoticeState': 'boolean',
        'legs': 'PublicTrafficInfoPilotageLeg[]',
        'pair': 'PilotageOverview',
        'relatedEndpointCodes': 'Locode[]',
        'relatedPilotStations': 'PilotStationId[]',
        'routeEnd': 'RouteEndpointInfo',
        'routeStart': 'RouteEndpointInfo',
        'scheduleSource': 'string',
        'shipAgentName': 'string',
        'startBerth': 'BerthCode',
        'startTime': 'Instant',
        'state': 'PilotageState',
        'updated': 'Instant',
        'vessel': 'PublicTrafficLogVesselInfo'
    });

    config.registerClassSerializer<PublicTrafficInfoPilotageLeg>('PublicTrafficInfoPilotageLeg', {
        'bookingId': 'PilotageId',
        'endTime': 'Instant',
        'pilotageId': 'PilotageId',
        'routeEnd': 'RouteEndpointInfo',
        'routeStart': 'RouteEndpointInfo',
        'startTime': 'Instant',
        'state': 'PilotageState'
    });

    config.registerClassSerializer<PublicTrafficInfoPortCall>('PublicTrafficInfoPortCall', {
        'active': 'boolean',
        'arrivalCompleted': 'boolean',
        'arrivalLastUpdated': 'Instant',
        'arrivalScheduleSource': 'string',
        'arrivalTime': 'Instant',
        'arrivalTimeFromPortnet': 'boolean',
        'arrivalTimeTooltip': 'string',
        'berth': 'string',
        'departureCompleted': 'boolean',
        'departureLastUpdated': 'Instant',
        'departureScheduleSource': 'string',
        'departureTime': 'Instant',
        'departureTimeFromPortnet': 'boolean',
        'departureTimeTooltip': 'string',
        'id': 'string',
        'inboundBookingId': 'PilotageId',
        'inboundPilotage': 'PublicTrafficInfoPortCallPilotage',
        'outboundBookingId': 'PilotageId',
        'outboundPilotage': 'PublicTrafficInfoPortCallPilotage',
        'portCode': 'Locode',
        'portName': 'string',
        'portNameFromPortnet': 'boolean',
        'relatedEndpointCodes': 'Locode[]',
        'relatedPilotStations': 'PilotStationId[]',
        'type': 'string',
        'vessel': 'PublicTrafficLogVesselInfo'
    });

    config.registerClassSerializer<PublicTrafficInfoPortCallPilotage>('PublicTrafficInfoPortCallPilotage', {
        'id': 'PilotageId',
        'state': 'PilotageState'
    });

    config.registerClassSerializer<PublicTrafficInfoPortEvent>('PublicTrafficInfoPortEvent', {
        'active': 'boolean',
        'berth': 'string',
        'bookingId': 'PilotageId',
        'brokerCompanyId': 'CompanyId',
        'id': 'string',
        'pilotageId': 'PilotageId',
        'pilotageState': 'PilotageState',
        'portCode': 'Locode',
        'portName': 'string',
        'portNameFromPortnet': 'boolean',
        'relatedEndpointCodes': 'Locode[]',
        'relatedPilotStations': 'PilotStationId[]',
        'scheduleSource': 'string',
        'time': 'Instant',
        'timeFromPortnet': 'boolean',
        'timeLastUpdated': 'Instant',
        'timeTooltip': 'string',
        'type': 'PublicTrafficPortEventType',
        'vessel': 'PublicTrafficLogVesselInfo'
    });

    config.registerClassSerializer<PublicTrafficLogVesselInfo>('PublicTrafficLogVesselInfo', {
        'callSign': 'string',
        'id': 'VesselId',
        'imo': 'IMO',
        'lastKnownVesselLocation': 'GeoPoint',
        'mmsi': 'MMSI',
        'name': 'string'
    });

    config.registerClassSerializer<PublicVesselInfo>('PublicVesselInfo', {
        'callSign': 'string',
        'draft': 'Draft',
        'id': 'VesselId',
        'imo': 'IMO',
        'mmsi': 'MMSI',
        'name': 'string',
        'netTonnage': 'Tonnage'
    });

    config.registerClassSerializer<ReferenceData>('ReferenceData', {
        'allPilotStations': 'PilotStationInfo[]',
        'areas': 'AreaInfo[]',
        'pilotageAreas': 'PilotageAreaInfo[]'
    });

    config.registerClassSerializer<ResendLoginLink>('ResendLoginLink', {
        'email': 'string'
    });

    config.registerClassSerializer<ReserverSimulatorSlot>('ReserverSimulatorSlot', {
        'test': 'SimulatorTestAcceptanceInfo',
        'time': 'LocalDateTime'
    });

    config.registerClassSerializer<RouteEndpointInfo>('RouteEndpointInfo', {
        'code': 'Locode',
        'defaultInboundDuration': 'Minutes',
        'id': 'EndpointId',
        'name': 'string',
        'type': 'EndpointType'
    });

    config.registerClassSerializer<RouteSearchResultRow>('RouteSearchResultRow', {
        'endCode': 'Locode',
        'endId': 'EndpointId',
        'endName': 'string',
        'endType': 'EndpointType',
        'id': 'string',
        'label': 'string',
        'startCode': 'Locode',
        'startId': 'EndpointId',
        'startName': 'string'
    });

    config.registerClassSerializer<RouteStartResults>('RouteStartResults', {
        'routes': 'RouteSearchResultRow[]',
        'startLocationName': 'string'
    });

    config.registerClassSerializer<ShipSimulatorTestReservationDetails>('ShipSimulatorTestReservationDetails', {
        'billingData': 'StandalonePecBillingEditData',
        'estimatedHours': 'Hours',
        'fairwayIds': 'PecFairwayId[]',
        'notes': 'string',
        'orderTime': 'LocalDateTime',
        'simulatorProviderId': 'SimulatorProviderId',
        'vesselId': 'VesselId'
    });

    config.registerClassSerializer<SimulatorProviderFairways>('SimulatorProviderFairways', {
        'fairwayIds': 'PecFairwayId[]'
    });

    config.registerClassSerializer<SimulatorProviderInfo>('SimulatorProviderInfo', {
        'fairwayIds': 'PecFairwayId[]',
        'id': 'SimulatorProviderId',
        'name': 'string',
        'timeslots': 'LocalDateTime[]'
    });

    config.registerClassSerializer<SimulatorProviderUserEditData>('SimulatorProviderUserEditData', {
        'email': 'EmailAddress',
        'name': 'string'
    });

    config.registerClassSerializer<SimulatorProviderUserInfo>('SimulatorProviderUserInfo', {
        'email': 'EmailAddress',
        'id': 'SimulatorProviderUserId',
        'name': 'string'
    });

    config.registerClassSerializer<SimulatorSlotUpdate>('SimulatorSlotUpdate', {
        'available': 'boolean',
        'slots': 'LocalDateTime[]'
    });

    config.registerClassSerializer<SimulatorSlotsServerState>('SimulatorSlotsServerState', {
        'availableSlots': 'LocalDateTime[]',
        'reservedSlots': 'ReserverSimulatorSlot[]'
    });

    config.registerClassSerializer<SimulatorTestAcceptanceInfo>('SimulatorTestAcceptanceInfo', {
        'acceptanceState': 'SimulatorProviderAcceptanceState',
        'billingData': 'PecBillingData',
        'candidate': 'PecBookingCandidateData',
        'endTime': 'Instant',
        'fairwayNames': 'string',
        'startTime': 'Instant',
        'testId': 'PecTestId',
        'vesselName': 'string'
    });

    config.registerClassSerializer<SimulatorTestRejectDto>('SimulatorTestRejectDto', {
        'reason': 'string'
    });

    config.registerClassSerializer<StandalonePecBillingEditData>('StandalonePecBillingEditData', {
        'addressLine1': 'string',
        'addressLine2': 'string',
        'businessId': 'string',
        'countryId': 'CountryId',
        'eInvoiceAddress': 'OvtInvoiceAddress',
        'email': 'EmailAddress',
        'organizationName': 'string'
    });

    config.registerClassSerializer<StandalonePecData>('StandalonePecData', {
        'candidate': 'PecBookingCandidateData',
        'fairwayTestReservationDetails': 'FairwayKnowledgeReservationDetails',
        'shipSimulatorTestReservationDetails': 'ShipSimulatorTestReservationDetails',
        'type': 'PecType'
    });

    config.registerClassSerializer<TugsRequestedInfo>('TugsRequestedInfo', {
        'selectionLabel': 'string',
        'totalTugs': 'number',
        'value': 'TugsRequested'
    });

    config.registerClassSerializer<UpdateScheduleDto>('UpdateScheduleDto', {
        'message': 'string',
        'startTime': 'Instant'
    });

    config.registerClassSerializer<VesselAisData>('VesselAisData', {
        'lastUpdateTime': 'Instant',
        'location': 'VesselLocationEvent',
        'metadata': 'VesselMetadataEvent',
        'mmsi': 'MMSI'
    });

    config.registerClassSerializer<VesselDetails>('VesselDetails', {
        'beam': 'number',
        'callSign': 'string',
        'capacity': 'Tonnage',
        'draft': 'Draft',
        'draftBounds': 'VesselDraftBounds',
        'email': 'EmailAddress',
        'enginePower': 'string',
        'grossTonnage': 'Tonnage',
        'homeHarbor': 'string',
        'iceClass': 'string',
        'id': 'VesselId',
        'imo': 'IMO',
        'manufacturingYear': 'number',
        'maxHeight': 'number',
        'maxLength': 'number',
        'measuredLength': 'number',
        'mmsi': 'MMSI',
        'name': 'string',
        'nationality': 'string',
        'netTonnage': 'Tonnage',
        'phoneNumber': 'string',
        'phoneNumber2': 'string',
        'pilotonlineUser': 'boolean',
        'propellerCount': 'string',
        'propellerType': 'string',
        'rudderCount': 'string',
        'rudderPropellers': 'string',
        'rudderType': 'string',
        'speed': 'number',
        'vesselClass': 'string'
    });

    config.registerClassSerializer<VesselDraftBounds>('VesselDraftBounds', {
        'iceDraftAft': 'DraftBounds',
        'iceDraftFore': 'DraftBounds',
        'maxDraft': 'DraftBounds'
    });

    config.registerClassSerializer<VesselLocationEvent>('VesselLocationEvent', {
        'cog': 'number',
        'heading': 'number',
        'mmsi': 'MMSI',
        'navStat': 'number',
        'point': 'GeoPoint',
        'posAcc': 'boolean',
        'rot': 'number',
        'sog': 'number',
        'timestamp': 'Instant'
    });

    config.registerClassSerializer<VesselMetadataEvent>('VesselMetadataEvent', {
        'destination': 'string',
        'mmsi': 'MMSI',
        'name': 'string',
        'shipType': 'number'
    });

    config.registerClassSerializer<VtsZoneInfo>('VtsZoneInfo', {
        'fairways': 'PecFairwayInfo[]',
        'id': 'VtsZoneId',
        'name': 'string'
    });

    config.registerClassSerializer<Warning>('Warning', {
        'text': 'string',
        'time': 'Instant'
    });

    config.registerClassSerializer<Broker>('Broker', {
        'id': 'BrokerId',
        'jwtToken': 'JwtToken',
        'profile': 'BrokerProfile'
    });

    config.registerClassSerializer<Internal>('Internal', {
        'jwtToken': 'JwtToken',
        'permissions': 'PilotOnlinePermission[]'
    });

    config.registerClassSerializer<MakeNotice>('MakeNotice', {
        'enabled': 'boolean',
        'highlight': 'boolean',
        'longText': 'string',
        'needsDraft': 'boolean',
        'needsEtaConfirmation': 'boolean',
        'scheduleStyle': 'string',
        'text': 'string'
    });

    config.registerClassSerializer<MakeOrder>('MakeOrder', {
        'enabled': 'boolean',
        'highlight': 'boolean',
        'longText': 'string',
        'needsDraft': 'boolean',
        'needsEtaConfirmation': 'boolean',
        'scheduleStyle': 'string',
        'text': 'string'
    });

    config.registerClassSerializer<NoAction>('NoAction', {
        'enabled': 'boolean',
        'highlight': 'boolean',
        'longText': 'string',
        'needsDraft': 'boolean',
        'needsEtaConfirmation': 'boolean',
        'scheduleStyle': 'string',
        'text': 'string'
    });

    config.registerClassSerializer<OnBoard>('OnBoard', {});

    config.registerClassSerializer<OnPilotStation>('OnPilotStation', {
        'pilotStationId': 'PilotStationId'
    });

    config.registerClassSerializer<Other>('Other', {
        'description': 'string'
    });

    config.registerClassSerializer<SimulatorProviderUser>('SimulatorProviderUser', {
        'id': 'SimulatorProviderUserId',
        'jwtToken': 'JwtToken'
    });

    config.registerClassSerializer<Vessel>('Vessel', {
        'id': 'VesselId',
        'jwtToken': 'JwtToken'
    });

    config.registerDiscriminatedUnionSerializer<FairwayKnowledgeTestLocation>('FairwayKnowledgeTestLocation', 'type', {
        'OnBoard': 'OnBoard',
        'OnPilotStation': 'OnPilotStation',
        'Other': 'Other'
    });

    config.registerDiscriminatedUnionSerializer<LoginResponse>('LoginResponse', 'type', {
        'Broker': 'Broker',
        'Internal': 'Internal',
        'SimulatorProviderUser': 'SimulatorProviderUser',
        'Vessel': 'Vessel'
    });

    config.registerDiscriminatedUnionSerializer<StateChangeAction>('StateChangeAction', 'type', {
        'MakeNotice': 'MakeNotice',
        'MakeOrder': 'MakeOrder',
        'NoAction': 'NoAction'
    });

}

declare const brand: unique symbol;

export type Branded<T, TBrand extends string> = T & { [brand]: TBrand };

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private readonly serializers: Record<string, Serializer> = {};

    constructor() {
        this.registerIdentitySerializer("any");
        this.registerIdentitySerializer("string");
        this.registerIdentitySerializer("number");
        this.registerIdentitySerializer("boolean");

        registerDefaultSerializers(this);
    }

    serialize(value: unknown, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: unknown, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, nullSafeSerializer({
            serialize: o => enumObject[o],
            deserialize: o => enumObject[o],
        }));
    }

    registerClassSerializer<T = unknown>(name: string, fields: Record<keyof T, string>) {
        this.registerSerializer(name, nullSafeSerializer({
            serialize: o => Object.fromEntries(Object.entries(fields).map(([name, type]) => [name, this.serialize(o[name], type as string)])),
            deserialize: o => Object.fromEntries(Object.entries(fields).map(([name, type]) => [name, this.deserialize(o[name], type as string)])),
        }));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, {
            serialize: o => o,
            deserialize: o => o
        });
    }

    registerDiscriminatedUnionSerializer<T = unknown>(name: string, discriminator: keyof T, types: Record<string, string>) {
        const self = this;
        this.registerSerializer(name, nullSafeSerializer({
            serialize(obj) {
                const localType = obj[discriminator];
                return {
                    ...self.lookupSerializer(types[localType]).serialize(obj),
                    [discriminator]: localType
                };
            },
            deserialize(obj) {
                const localType = obj[discriminator];
                return {
                    ...self.lookupSerializer(types[localType]).deserialize(obj),
                    [discriminator]: localType
                };
            }
        }));
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.endsWith("[]")) {
            const elementType = type.substring(0, type.length - 2);
            return arraySerializer(this.lookupSerializer(elementType));
        }

        const dictionaryMatch = /^Record<string,\s*(.+)>$/.exec(type);
        if (dictionaryMatch)
            return dictionarySerializer(this.lookupSerializer(dictionaryMatch[1]));

        const serializer = this.serializers[type];
        if (serializer)
            return serializer;

        throw new Error(`could not find serializer for type '${type}'`);
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    return nullSafeSerializer({
        serialize: o => o.map((v: any) => elementSerializer.serialize(v)),
        deserialize: o => o.map((v: any) => elementSerializer.deserialize(v)),
    });
}

function dictionarySerializer(elementSerializer: Serializer): Serializer {
    return nullSafeSerializer({
        serialize: o => Object.fromEntries(Object.entries(o).map(([k, v]) => [k, elementSerializer.serialize(v)])),
        deserialize: o => Object.fromEntries(Object.entries(o).map(([k, v]) => [k, elementSerializer.deserialize(v)])),
    });
}

function formatQueryParameters(params: Record<string, unknown>): string {
    const components: string[] = [];

    const addQueryParameter = (encodedKey: string, value: any): void => {
        if (value != null)
            components.push(`${encodedKey}=${encodeURIComponent(value)}`);
    };

    for (const [key, value] of Object.entries(params || {})) {
        const encodedKey = encodeURIComponent(key);

        if (Array.isArray(value)) {
            for (const arrayItemValue of value)
                addQueryParameter(encodedKey, arrayItemValue);
        } else {
            addQueryParameter(encodedKey, value);
        }
    }

    return components.length > 0 ? '?' + components.join('&') : '';
}

export interface UrlData {
    uriTemplate: string;
    pathVariables?: any;
    requestParams?: any;
}

export interface RequestData extends UrlData {
    method: string;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer<A = any, B = any> {
    serialize(o: A): B;

    deserialize(o: B): A;
}

function nullSafeSerializer<A, B>(serializer: Serializer<A, B>): Serializer<A | null | undefined, B | null | undefined> {
    return {
        serialize: (o: A) => (o === null) ? null : (o === undefined) ? undefined : serializer.serialize(o),
        deserialize: (o: B) => (o === null) ? null : (o === undefined) ? undefined : serializer.deserialize(o),
    };
}

export abstract class ApinaEndpointContext {

    constructor(protected readonly config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    url(data: UrlData): string {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);
        return url + formatQueryParameters(data.requestParams);
    }

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    protected buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (_match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private readonly httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }

        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

interface ProvideParams {
    config?: ApinaConfig;
    endpointContextClass?: Type<ApinaEndpointContext>;
}

export function provideApina(params: ProvideParams = {}): Provider[] {
    return [
        { provide: ApinaConfig, useValue: params.config ?? new ApinaConfig() },
        { provide: ApinaEndpointContext, useClass: params.endpointContextClass ?? DefaultApinaEndpointContext },
    ];
}

@Injectable({providedIn: 'root'})
export class PilotOnlineEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    calculatePrice(routeId: number, chain: boolean, netTonnage: Tonnage): Observable<PilotagePriceData[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pilotage-price',
            'method': 'GET',
            'requestParams': {
                'routeId': this.context.serialize(routeId, 'number'),
                'chain': this.context.serialize(chain, 'boolean'),
                'netTonnage': this.context.serialize(netTonnage, 'Tonnage')
            },
            'responseType': 'PilotagePriceData[]'
        });
    }

    createBooking(dto: CreateBookingDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking',
            'method': 'POST',
            'requestBody': this.context.serialize(dto, 'CreateBookingDto')
        });
    }

    createOutboundBooking(dto: CreateOutboundBookingDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/outbound-booking',
            'method': 'POST',
            'requestBody': this.context.serialize(dto, 'CreateOutboundBookingDto')
        });
    }

    editVesselDetailsForBooking(id: BookingId, vessel: EditBookingVesselDetails): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}/vessel',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'requestBody': this.context.serialize(vessel, 'EditBookingVesselDetails')
        });
    }

    findActiveBookings(): Observable<ActiveBooking[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/active-bookings',
            'method': 'GET',
            'responseType': 'ActiveBooking[]'
        });
    }

    findBerthsForRoute(portIds: number[]): Observable<BerthInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/berths-for-route',
            'method': 'GET',
            'requestParams': {
                'portIds': this.context.serialize(portIds, 'number[]')
            },
            'responseType': 'BerthInfo[]'
        });
    }

    findBookingActivity(id: BookingId): Observable<BookingDetails> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'responseType': 'BookingDetails'
        });
    }

    findCompanies(query: string, limit?: number | null): Observable<FindCompanyInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/companies',
            'method': 'GET',
            'requestParams': {
                'query': this.context.serialize(query, 'string'),
                'limit': this.context.serialize(limit, 'number')
            },
            'responseType': 'FindCompanyInfo[]'
        });
    }

    findCompaniesRecentlyUsedByCurrentUser(): Observable<FindCompanyInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/companies/recently-used',
            'method': 'GET',
            'responseType': 'FindCompanyInfo[]'
        });
    }

    findCompanyBydId(id: CompanyId): Observable<CompanyInfo> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/company/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'CompanyId')
            },
            'responseType': 'CompanyInfo'
        });
    }

    findNewBookingDetails(vesselId: VesselId, companyId: CompanyId, startPortBerth?: number | null): Observable<NewBookingDetails> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/new-booking',
            'method': 'GET',
            'requestParams': {
                'vesselId': this.context.serialize(vesselId, 'VesselId'),
                'companyId': this.context.serialize(companyId, 'CompanyId'),
                'startPortBerth': this.context.serialize(startPortBerth, 'number')
            },
            'responseType': 'NewBookingDetails'
        });
    }

    findPorts(addReadonlyPorts: boolean): Observable<PortsDto> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/ports',
            'method': 'GET',
            'requestParams': {
                'addReadonlyPorts': this.context.serialize(addReadonlyPorts, 'boolean')
            },
            'responseType': 'PortsDto'
        });
    }

    findRoutesAndHarbors(query: string): Observable<RouteSearchResultRow[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/routes',
            'method': 'GET',
            'requestParams': {
                'query': this.context.serialize(query, 'string')
            },
            'responseType': 'RouteSearchResultRow[]'
        });
    }

    findRoutesForStartPoint(routeStartCode: Locode): Observable<RouteStartResults> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/routes-for-start-point',
            'method': 'GET',
            'requestParams': {
                'routeStartCode': this.context.serialize(routeStartCode, 'Locode')
            },
            'responseType': 'RouteStartResults'
        });
    }

    findTugsRequestedOptions(): Observable<TugsRequestedInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/metadata/tugs-requested',
            'method': 'GET',
            'responseType': 'TugsRequestedInfo[]'
        });
    }

    findVesselDetailsForBooking(id: BookingId): Observable<BookingVesselDetails> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}/vessel',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'responseType': 'BookingVesselDetails'
        });
    }

    findVessels(query: string, limit?: number | null): Observable<FindVesselsResults> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/vessels',
            'method': 'GET',
            'requestParams': {
                'query': this.context.serialize(query, 'string'),
                'limit': this.context.serialize(limit, 'number')
            },
            'responseType': 'FindVesselsResults'
        });
    }

    findVesselsRecentlyUsedByCurrentUser(): Observable<PublicVesselInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/vessels/recently-used',
            'method': 'GET',
            'responseType': 'PublicVesselInfo[]'
        });
    }

    getBrokerProfileForEdit(): Observable<EditBrokerProfile> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/broker-profile',
            'method': 'GET',
            'responseType': 'EditBrokerProfile'
        });
    }

    getPilotagePriceCalculatorData(): Observable<PilotagePriceCalculatorData> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/price-calculator-routes',
            'method': 'GET',
            'responseType': 'PilotagePriceCalculatorData'
        });
    }

    getTrafficInfo(): Observable<PublicTrafficInfo> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/traffic-info',
            'method': 'GET',
            'responseType': 'PublicTrafficInfo'
        });
    }

    getVesselProfileForEdit(): Observable<EditableVesselData> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/vessel-profile',
            'method': 'GET',
            'responseType': 'EditableVesselData'
        });
    }

    makeNotice(id: BookingId, dto: AdvanceStateDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}/notice',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'requestBody': this.context.serialize(dto, 'AdvanceStateDto')
        });
    }

    makeOrder(id: BookingId, dto: AdvanceStateDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}/order',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'requestBody': this.context.serialize(dto, 'AdvanceStateDto')
        });
    }

    postComment(id: BookingId, dto: NewBrokerCommentDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}/comment',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'requestBody': this.context.serialize(dto, 'NewBrokerCommentDto')
        });
    }

    sendFeedback(feedback: FeedbackDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/feedback',
            'method': 'POST',
            'requestBody': this.context.serialize(feedback, 'FeedbackDto')
        });
    }

    typedPilotOnlineMessage(): Observable<PilotOnlineMessage> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/pilot-online-message',
            'method': 'GET',
            'responseType': 'PilotOnlineMessage'
        });
    }

    updateBrokerProfile(edit: EditableBrokerData): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/broker-profile',
            'method': 'PUT',
            'requestBody': this.context.serialize(edit, 'EditableBrokerData')
        });
    }

    updatePilotageFollowing(bookingId: BookingId, follow: boolean): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/follow-pilotage',
            'method': 'PUT',
            'requestParams': {
                'bookingId': this.context.serialize(bookingId, 'BookingId'),
                'follow': this.context.serialize(follow, 'boolean')
            }
        });
    }

    updateSchedule(id: BookingId, dto: UpdateScheduleDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/booking/{id}/update-schedule',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'BookingId')
            },
            'requestBody': this.context.serialize(dto, 'UpdateScheduleDto')
        });
    }

    updateVesselProfile(edit: EditableVesselData): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/vessel-profile',
            'method': 'PUT',
            'requestBody': this.context.serialize(edit, 'EditableVesselData')
        });
    }

}

@Injectable({providedIn: 'root'})
export class PilotOnlineAisEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    getCurrentAisData(): Observable<CurrentAisData> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/ais/ais-data',
            'method': 'GET',
            'responseType': 'CurrentAisData'
        });
    }

}

@Injectable({providedIn: 'root'})
export class PilotOnlineLoginEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    getLoginSettings(): Observable<OAuthLoginSettings> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/settings',
            'method': 'GET',
            'responseType': 'OAuthLoginSettings'
        });
    }

    loginWithAzureToken(accessToken: string): Observable<LoginResponse> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/login-with-azure-token',
            'method': 'POST',
            'requestParams': {
                'accessToken': this.context.serialize(accessToken, 'string')
            },
            'responseType': 'LoginResponse'
        });
    }

    loginWithSessionToken(token: JwtToken): Observable<LoginResponse> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/login-with-session-token',
            'method': 'POST',
            'requestParams': {
                'token': this.context.serialize(token, 'JwtToken')
            },
            'responseType': 'LoginResponse'
        });
    }

    loginWithToken(token: PilotOnlineLoginToken): Observable<LoginResponse> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/login-with-token',
            'method': 'POST',
            'requestParams': {
                'token': this.context.serialize(token, 'PilotOnlineLoginToken')
            },
            'responseType': 'LoginResponse'
        });
    }

    registerAsBroker(application: ApplicationForBrokerRegistration): Observable<RegisterAsBrokerResult> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/register-as-broker',
            'method': 'POST',
            'requestBody': this.context.serialize(application, 'ApplicationForBrokerRegistration'),
            'responseType': 'RegisterAsBrokerResult'
        });
    }

    registerAsVesselUser(application: ApplicationForVesselUserRegistration): Observable<RegisterAsVesselUserResult> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/register-as-vessel-user',
            'method': 'POST',
            'requestBody': this.context.serialize(application, 'ApplicationForVesselUserRegistration'),
            'responseType': 'RegisterAsVesselUserResult'
        });
    }

    resendLoginLink(data: ResendLoginLink): Observable<ResendLoginLinkResult> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/login/resend-pilot-online-login-link',
            'method': 'POST',
            'requestBody': this.context.serialize(data, 'ResendLoginLink'),
            'responseType': 'ResendLoginLinkResult'
        });
    }

}

@Injectable({providedIn: 'root'})
export class PilotOnlinePecEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    acceptShipSimulatorTest(id: PecTestId): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-test/{id}/accept',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'PecTestId')
            }
        });
    }

    createFairwayKnowledgeTest(data: PecBookingCandidateData): Observable<InitiateBookingResponse> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/fairway-knowledge-test',
            'method': 'POST',
            'requestBody': this.context.serialize(data, 'PecBookingCandidateData'),
            'responseType': 'InitiateBookingResponse'
        });
    }

    createSimulatorProviderUser(data: SimulatorProviderUserEditData): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/users/new',
            'method': 'POST',
            'requestBody': this.context.serialize(data, 'SimulatorProviderUserEditData')
        });
    }

    createSimulatorTest(data: PecBookingCandidateData): Observable<InitiateBookingResponse> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-test',
            'method': 'POST',
            'requestBody': this.context.serialize(data, 'PecBookingCandidateData'),
            'responseType': 'InitiateBookingResponse'
        });
    }

    findSimulatorProviderWithTimeSlots(): Observable<SimulatorProviderInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-providers',
            'method': 'GET',
            'responseType': 'SimulatorProviderInfo[]'
        });
    }

    findSimulatorSlotsForCurrentOrganization(): Observable<SimulatorSlotsServerState> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-slots',
            'method': 'GET',
            'responseType': 'SimulatorSlotsServerState'
        });
    }

    findSimulatorTestToAccept(id: PecTestId): Observable<SimulatorTestAcceptanceInfo> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-test/{id}/accept-data',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'PecTestId')
            },
            'responseType': 'SimulatorTestAcceptanceInfo'
        });
    }

    getPilotagePlanUploadData(token: PecTestBookingToken, fileName: string): Observable<PilotagePlanUploadData> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/fairway-knowledge-test/pilotage-plan-upload-data/{token}',
            'method': 'GET',
            'pathVariables': {
                'token': this.context.serialize(token, 'PecTestBookingToken')
            },
            'requestParams': {
                'fileName': this.context.serialize(fileName, 'string')
            },
            'responseType': 'PilotagePlanUploadData'
        });
    }

    getStandalonePecTestData(token: PecTestBookingToken): Observable<StandalonePecData> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/test/{token}',
            'method': 'GET',
            'pathVariables': {
                'token': this.context.serialize(token, 'PecTestBookingToken')
            },
            'responseType': 'StandalonePecData'
        });
    }

    getSupportedFairwaysForCurrentOrganization(): Observable<SimulatorProviderFairways> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/supported-fairways',
            'method': 'GET',
            'responseType': 'SimulatorProviderFairways'
        });
    }

    getUsersForCurrentOrganization(): Observable<SimulatorProviderUserInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/users',
            'method': 'GET',
            'responseType': 'SimulatorProviderUserInfo[]'
        });
    }

    rejectShipSimulatorTest(id: PecTestId, data: SimulatorTestRejectDto): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-test/{id}/reject',
            'method': 'POST',
            'pathVariables': {
                'id': this.context.serialize(id, 'PecTestId')
            },
            'requestBody': this.context.serialize(data, 'SimulatorTestRejectDto')
        });
    }

    removeSimulatorProviderUser(userId: SimulatorProviderUserId): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/users/{userId}',
            'method': 'DELETE',
            'pathVariables': {
                'userId': this.context.serialize(userId, 'SimulatorProviderUserId')
            }
        });
    }

    requestNewLoginLink(userId: SimulatorProviderUserId): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/users/{userId}/request-new-login-link',
            'method': 'POST',
            'pathVariables': {
                'userId': this.context.serialize(userId, 'SimulatorProviderUserId')
            }
        });
    }

    submitFairwayKnowledgeDetails(id: PecTestBookingToken, details: FairwayKnowledgeReservationDetails): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/fairway-knowledge-test/details/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'PecTestBookingToken')
            },
            'requestBody': this.context.serialize(details, 'FairwayKnowledgeReservationDetails')
        });
    }

    submitShipSimulatorTestDetails(id: PecTestBookingToken, details: ShipSimulatorTestReservationDetails): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/ship-simulator-test/details/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'PecTestBookingToken')
            },
            'requestBody': this.context.serialize(details, 'ShipSimulatorTestReservationDetails')
        });
    }

    updateSimulatorProviderUser(userId: SimulatorProviderUserId, data: SimulatorProviderUserEditData): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/users/{userId}',
            'method': 'PUT',
            'pathVariables': {
                'userId': this.context.serialize(userId, 'SimulatorProviderUserId')
            },
            'requestBody': this.context.serialize(data, 'SimulatorProviderUserEditData')
        });
    }

    updateSimulatorSlotsForCurrentOrganization(update: SimulatorSlotUpdate): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/simulator-slots/update',
            'method': 'POST',
            'requestBody': this.context.serialize(update, 'SimulatorSlotUpdate')
        });
    }

    updateSupportedFairwaysForCurrentOrganization(update: SimulatorProviderFairways): Observable<void> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec/supported-fairways',
            'method': 'PUT',
            'requestBody': this.context.serialize(update, 'SimulatorProviderFairways')
        });
    }

}

@Injectable({providedIn: 'root'})
export class PilotOnlineReferenceDataEndpoint {
    constructor(private readonly context: ApinaEndpointContext) {
    }

    findCountries(lang: Language): Observable<CountryInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/countries',
            'method': 'GET',
            'requestParams': {
                'lang': this.context.serialize(lang, 'Language')
            },
            'responseType': 'CountryInfo[]'
        });
    }

    findPecFairwaysByZone(): Observable<VtsZoneInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/pec-fairways-by-zone',
            'method': 'GET',
            'responseType': 'VtsZoneInfo[]'
        });
    }

    findVessels(): Observable<PublicVesselInfo[]> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/vessels',
            'method': 'GET',
            'responseType': 'PublicVesselInfo[]'
        });
    }

    loadReferenceData(lang: Language): Observable<ReferenceData> {
        return this.context.request({
            'uriTemplate': '/pilot-online/api/public/load-reference-data',
            'method': 'GET',
            'requestParams': {
                'lang': this.context.serialize(lang, 'Language')
            },
            'responseType': 'ReferenceData'
        });
    }

}

