<h3 mat-dialog-title>Lepo</h3>
<mat-dialog-content>
    <app-vertical-form>
        <mat-form-field>
            <mat-label>Levon alku</mat-label>
            <input matInput type="text" readonly [value]="startTime | helsinkiDate">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Lepo päättyy</mat-label>
            <app-mat-date-time-picker [required]="true" name="endTime" [formControl]="endTime" [min]="minTime" [max]="maxTime"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Kesto</mat-label>
            <input matInput disabled value="{{duration}}">
        </mat-form-field>
    </app-vertical-form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">Peruuta</button>
    <app-spinner-button color="primary" (spinnerClick)="save()" [disabled]="endTime.value == null" [active]="saving()">Tallenna</app-spinner-button>
</mat-dialog-actions>
