import { ChangeDetectionStrategy, Component, effect, Signal } from "@angular/core";
import { AugmentedSchedulingPilot, SchedulingData, SchedulingService } from "./services/scheduling.service";
import { SchedulingEndpoint, SchedulingPilot, TrafficLogPilotage } from "apina-frontend";
import { createSchedulingFilterParams, SchedulingFiltersComponent, SchedulingQueryParams } from "./filters/scheduling-filters.component";
import { Title } from "@angular/platform-browser";
import { FrontendEnvironmentService } from "../config/frontend-environment.service";
import { buildUrl, ErrorService } from "common";
import { ActivatedRoute } from "@angular/router";
import { PilotageElement } from "./services/scheduling.model";
import { parsePilotageIdOrNull } from "../domain/id-parsing";
import { ScheduleLaneComponent } from "./schedule-lane/schedule-lane.component";
import { PilotLocationComponent } from "./pilot-location/pilot-location.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

const SCHEDULING_PARAM_TARGETED_PILOTAGE_ID = "targetedPilotageId";

@Component({
    selector: 'app-scheduling',
    templateUrl: 'scheduling.component.html',
    providers: [SchedulingService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DragDropModule,
        MatButtonModule,
        MatCardModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        PilotLocationComponent,
        ScheduleLaneComponent,
        SchedulingFiltersComponent,
    ],
    host: {
        'class': 'mat-typography'
    },
})
export class SchedulingComponent {

    readonly data: Signal<SchedulingData | null>;

    constructor(
        private readonly schedulingService: SchedulingService,
        private readonly frontendEnvironmentService: FrontendEnvironmentService,
        private readonly schedulingEndpoint: SchedulingEndpoint,
        private readonly errorService: ErrorService,
        route: ActivatedRoute,
        title: Title,
    ) {
        title.setTitle("Suunnittelunäkymä");

        schedulingService.pilotages.targetedPilotage = parsePilotageIdOrNull(route.snapshot.queryParamMap.get(SCHEDULING_PARAM_TARGETED_PILOTAGE_ID));

        this.data = this.schedulingService.results;

        effect(() => {
            this.reload();
        }, {allowSignalWrites: true});
    }

    updateQueryParams(params: SchedulingQueryParams): void {
        this.schedulingService.search(params);
    }

    reload(): void {
        this.schedulingService.refreshData();
    }

    allocationTableLength(marks: string): number {
        const whitespace = marks.indexOf(" ");
        return (whitespace !== -1) ? whitespace : marks.length;
    }

    pilotTooltip(pilot: SchedulingPilot): string {
        const lines = [
            `${pilot.firstName} ${pilot.lastName}`
        ];

        if (pilot.remark !== '' && pilot.remark !== '-')
            lines.push(pilot.remark);

        return lines.join("\n");
    }

    get showCreateTestPilotages(): boolean {
        return !this.frontendEnvironmentService.isProductionEnvironment();
    }

    createTestPilotages(): void {
        // eslint-disable-next-line no-alert
        if (confirm("Haluatko varmasti luoda testi-luotsauksia?\n\nTesti-luotsaukset luodaan Emäsalon asemalle.")) {
            this.schedulingEndpoint.createDummyPilotages().subscribe({
                next: () => this.reload(),
                error: e => this.errorService.showUpdateError(e)
            });
        }
    }

    setDraggedPilotage(pilot: AugmentedSchedulingPilot | null, pilotage: PilotageElement | null): void {
        if (pilot == null) return;
        pilot.dragged = pilotage != null;
    }
}

export function createSchedulingUrl(pilotage: TrafficLogPilotage): string {
    return buildUrl("/scheduling/for-pilotage", {
        ...createSchedulingFilterParams(pilotage),
        [SCHEDULING_PARAM_TARGETED_PILOTAGE_ID]: pilotage.pilotageId.toString(),
    });
}
