<mat-card appearance="raised">
    <mat-card-content>
        <div class="flex flex-row gap-4 flex-wrap">
            <app-scheduling-filters (queryParams)="updateQueryParams($event)"/>

            @if (showCreateTestPilotages) {
                <div class="fill-remaining-space"></div>
                <button mat-button color="warn" (click)="createTestPilotages()" class="whitespace-nowrap">Luo testi-luotsauksia</button>
            }
        </div>
    </mat-card-content>
</mat-card>

@if (data(); as data) {
    <section class="select-none p-2" style="width: calc(100% - 10px)" cdkDropListGroup>
        @if (data.timeRange != null) {
            <div class="flex flex-row">
                <div class="self-center w-[220px]">
                    Kiinnittämättömät
                </div>
                <div class="fill-remaining-space">
                    <app-schedule-lane [timeRange]="data.timeRange" [showTimeLane]="true"/>
                </div>
            </div>

            @for (pilot of data.pilots; track pilot.id; let firstPilot = $first) {
                <div class="flex flex-row">
                    <div class="flex flex-row items-stretch justify-between self-end w-[220px] text-xs">
                        <div class="whitespace-nowrap overflow-clip text-ellipsis w-[140px]"
                             [matTooltip]="pilotTooltip(pilot)"
                             matTooltipClass="multiline">
                            <div [class.text-gray-500]="!pilot.mayModifyAssignmentOfPilot"
                                 [class.text-orange]="pilot.dragged">
                                {{ pilot.initials }}
                            </div>

                            @if (pilot.marks != null || pilot.marks1 != null || pilot.marks2 != null) {
                                <div class="font-mono text-black text-[7pt]">
                                    @if (pilot.marks != null) {
                                        <span>{{ allocationTableLength(pilot.marks) }}: <span>{{ pilot.marks }}</span></span>
                                    }
                                    @if (pilot.marks1 != null) {
                                        <span> | {{ pilot.marks1 }}</span>
                                    }
                                    @if (pilot.marks2 != null) {
                                        <span> | {{ pilot.marks2 }}</span>
                                    }
                                </div>
                            }
                        </div>
                        <app-pilot-location class="w-[80px] mr-1" [pilot]="pilot" (restChanged)="reload()"/>
                    </div>
                    <div class="fill-remaining-space">
                        <app-schedule-lane [pilot]="pilot" [timeRange]="data.timeRange" [showTimeLane]="firstPilot" (pilotageDragged)="setDraggedPilotage(pilot, $event)"/>
                    </div>
                </div>
            } @empty {
                <p class="text-orange !my-4">Valituilla asemilla ole ole luotseja.</p>
            }
        } @else {
            <p class="text-orange !m-4">Aluevalintoja ei ole tehty.</p>
        }
    </section>
} @else {
    <mat-spinner class="default-spinner"/>
}
