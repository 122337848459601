@if (data(); as d) {
    <div class="h-full flex flex-col items-end overflow-clip text-[10px] leading-[10px] text-right">
        <div class="flex-1">{{ d.location.name }}</div>
        <div class="flex-1 pilot-status text-de-emphasized">
            @if (d.allowRestChange) {
                <a class="text-de-emphasized" href="" (click)="setRest($event)">{{ d.location.status }}</a>
            } @else {
                <span>{{ d.location.status }}</span>
            }
            <div>{{ d.details }}</div>
        </div>
    </div>
}
